import React from 'react';
import ForgotPasswordPrompt from '../../components/auth/ForgotPasswordForm';
import { TITLE } from '../../utils/Constants';

interface IProps {}

interface IState {}

export default class LoginPage extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        document.title = TITLE.FORGOT_PASS;
    }

    render() {
        return (
            <div className="route-page">
                <ForgotPasswordPrompt />
            </div>
        );
    }

}