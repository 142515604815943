import React from "react";

export default DefaultCompare;

export function DefaultCompare(a: React.ReactNode, b: React.ReactNode, by: string): number {
    if(by && React.isValidElement(a) && React.isValidElement(b)) {
        if (a.props[by] < b.props[by]) return -1;
        if (a.props[by] > b.props[by]) return 1;
        return 0;
    } else {
        return compare(a,b);
    }
}

function compare(a:any, b:any){
    if (!a && b) return -1;
    if (a && !b) return 1;
    if (a && b) {
        if (a < b) return -1;
        if (a > b) return 1;
    }
    return 0;
}