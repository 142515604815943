import React, { CSSProperties } from 'react';
import { CloseButton } from 'react-bootstrap';

export interface IProps {
    className?: string;
    style?: CSSProperties;
    closable?: boolean;
    title?: string;
    onClickClose?: (event: any) => void;
    onClickNo?: (event: any) => void;
}

interface IState {

}

export default class Prompt extends React.Component<IProps, IState> {

    render() {
        return (
            <div className={"page-container container-fluid"+this.props.className} style={{...style, ...this.props.style}}>
                { this.props.title || this.props.closable ? <div className="row">
                    <h2 className="col-11">{this.props.title}</h2>
                    { this.props.closable ? <CloseButton className="col-1" onClick={this.props.onClickClose ? this.props.onClickClose : this.props.onClickNo} /> : null}
                </div> : null }
                {this.props.children}
            </div>
        );
    }

}

const style: CSSProperties = {
    maxWidth:'500px',
    margin:'75px auto'
}