export const ROUTE = {
    HOME: "/",
    // loot table
    LOOT_TABLE: "/loottable",
    LOOT_TABLE_EDIT: "/loottable/e/",
    LOOT_TABLE_VIEW: "/loottable/v/",
    // loot
    LOOT: "/loot",
    LOOT_EDIT: "/loot/e/",
    LOOT_VIEW: "/loot/v/",
    LOOT_HISTORY: "/loot/history",
    // auth
    LOGIN: "/login",
    SIGNUP: "/signup",
    FORGOT_PASS: "/passwordreset",
    SETTINGS: "/settings",
    // other
    USER_PROFILE: "/user",
    HOW_IT_WORKS: "/howitworks",
    ABOUT: "/about",
    PRIVACY: "/privacy",
    TOS: "/tos",
    FOUNDERS: "/founders",
    NO_ROUTE: "*"
}

export const URL = {
    ALDERMAN: "https://aldermanrpg.com",
    ROADMAP: "https://trello.com/b/v8tttbl7/alderman-app-roadmap",
    FEEDBACK: "https://docs.google.com/forms/d/e/1FAIpQLSfI5ce9LxDmKg9LrroBnIeBQ7KXteqAZoaXide-nX0LPYuZgg/viewform?usp:sf_link",
    DONATE: "https://www.buymeacoffee.com/8BitAlex",
    TWITTER: "https://twitter.com/AldermanRPG",
    DISCORD: "https://discord.gg/8XZPASyr4u",
    REDDIT: "https://www.reddit.com/r/aldermanrpg/",
    HOW_TO_LOOT: "https://www.alexsalerno.com/index.php/2022/09/14/how-loot-and-loot-tables-work-in-alderman-rpg/",
    BLOG: "https://www.alexsalerno.com/index.php/category/software-engineering/alderman-rpg/"
}

export const TITLE = {
    HOME: "Alderman RPG",
    // loot table
    LOOTTABLE: "Loot Tables | Alderman RPG",
    LOOTTABLE_EDIT: "Edit Loot Table | Alderman RPG",
    // loot
    LOOT: "Loot | Alderman RPG",
    LOOT_EDIT: "Edit Loot | Alderman RPG",
    LOOT_HISTORY: "Loot History | Alderman RPG",
    // auth
    LOGIN: "Login | Alderman RPG",
    SIGNUP: "Sign Up |Alderman RPG",
    FORGOT_PASS: "Reset Password | Alderman RPG",
    SETTINGS: "Settings | Alderman RPG",
    // other
    USER_PROFILE: "Profile | Alderman RPG",
    HOW_IT_WORKS: "How It Works | Alderman RPG",
    ABOUT: "About Us | Alderman RPG",
    PRIVACY: "Privacy | Alderman RPG",
    TOS: "Terms & Conditions | Alderman RPG"
}

/**
 * Reactive Sizing
 * 
 * Size is >= current screen size. Ex. if(screenSize >= LARGE) isLarge = true
 */
export const SCREEN = {
    XLARGE: 1200,
    LARGE: 992,
    MEDIUM: 768,
    SMALL: 576,
    XSMALL: 0
}

export const REGEX = {
    ALPHANUMERIC: /^[A-Za-z0-9 ]+$/i,
    NUMERIC: /^[0-9]+$/i,
    US_KEYBOARD: /^[a-zA-Z0-9\t\n ./<>?,:"'`~,!@#$%^&*()[\]{}_+:|\\-]*$/i,
    WEIGHT: /^[0-9]{1,5}(\.[0-9]{0,2})?$/i,
    COLLECTION: /^(@[A-Za-z]+:[A-Za-z]+)/,
    UUID: /.{8}-.{4}-.{4}-.{4}-.{12}/i,
    SYSTEM_COLLECTION: /^@System:/
}

export const COLOR = {
    PRIMARY_BRICK: '#B44',
    PRIMARY_TAN: '#DDD8D2',
    PRIMARY_GRAY: '#232c33',
    SECONDARY_BRICK: '#ff5757',
    SECONDARY_TAN: '#DDD8D2',
    PRIMARY_BG_COLOR: "#2B3840",
    SECONDARY_BG_COLOR: "#3e4950",
    TERTIARY_BG_COLOR: '#383d4d',
    GRAVATAR_BLUE: '#0A76B7',
    RARITY: {
        COMMON: '#33cc33',
        UNCOMMON: '#0099ff',
        RARE: '#cc33ff',
        EPIC: '#ffcc00',
        LEGENDARY: '#ff6600',
    }
}

export const CONFIG = {
    TOOLTIP: {
        DELAY: { show: 1200, hide: 500 }
    }
}

export const TEXT = {
    TAGLINE: "- Alderman is the definitive Game Masters toolkit for tabletop RPGs -",
    PRIVACY_NOTICE: "By continuing to use our site, you agree to our Terms of Service and Privacy Policy. You can learn more about how we use cookies by reviewing our Privacy Policy.",
    SYSTEM_LOOT_TOOLTIP: "Alderman Loot",
    SYSTEM_LOOT_TABLE_TOOLTIP: "Alderman Loot Table",
    GENDER_IDENTITY: ["Male", "Female", "Transgender", "Gender Fluid", "Non-Binary", "Other"],
    GENDER_PRONOUNS: ["She/Her", "He/Him", "They/Them", "Other"]
}

export const EVENT = {
    // behavior
    SELECT_FROM_LOOT_TABLE: "select_from_loot_table",
    RESET_LOCAL_STORAGE: "reset_local_storage",
    RESET_SESSION_STORAGE: "reset_session_storage",
    // auth
    NEW_USER_ACCOUNT: "new_user_account",
    USER_LOGIN: "user_login",
    FORGOT_PASSWORD: "user_reset_password",
    // loot
    NEW_LOOT_INSTANCE: "new_loot_instance",
    NEW_LOOT_TABLE_INSTANCE: "new_loot_table_instance",
    DELETE_LOOT_INSTANCE: "delete_loot_instance",
    DELETE_LOOT_TABLE_INSTANCE: "delete_loot_table_instance",
}

export const ACTION = {

}
