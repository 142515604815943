import { ToastContainer } from 'react-toastify';

export default function AToast(){
    return (
        <ToastContainer
            position="top-right"
            autoClose={5000}
            pauseOnHover
            icon={<img className="me-2" src="/favicon.ico" style={{height:'24px',width:'24px'}} alt='Alderman Favicon'/>}
            style={{paddingTop:'64px'}}
            pauseOnFocusLoss={false}
        />
    );
}