import React from 'react';
import { RevertButton, SubmitButton } from '../../components/system/buttons/CommonButtons';
import { SCREEN } from '../../utils/Constants';
import ContentBar from './ContentBar';

interface IProps {
    onSubmit?: (e: any) => void;
    onRevert?: (e: any) => void;
    isDirty?: boolean;
}

interface IState {
    isMobile: boolean;
}

export default class PersistanceHeader extends React.Component<IProps, IState> {

    constructor(props: IProps){
        super(props)
        this.state = {
            isMobile: window.innerWidth < SCREEN.MEDIUM
        }
    }

    componentDidMount(){
        // resize
        window.addEventListener('resize', () => {
            this.setState({
                isMobile: window.innerWidth < SCREEN.MEDIUM
            });
        }, false);
    }

    render() {
        const classes = "col-md-2 px-1 mb-1 align-self-center";
        return (
            <ContentBar>
                <ContentBar.ContentRight>
                    { this.props.isDirty ? <h3 className={classes} style={{textAlign:'right'}}>Pending Changes</h3> : null }
                    <div className={classes}><RevertButton disabled={!this.props.isDirty} style={{width:'100%'}} onClick={this.props.onRevert}>Revert</RevertButton></div>
                    <div className={classes}><SubmitButton disabled={!this.props.isDirty} style={{width:'100%'}} onClick={this.props.onSubmit}>Save</SubmitButton></div>
                </ContentBar.ContentRight>
            </ContentBar>
        );
    }

}
