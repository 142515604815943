import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import IValidator from '../../model/interfaces/IValidator';
import EditableField from './EditableField';

interface IProps {
    className?: string;
    style?: CSSProperties;
}

export default class ContentBlock extends React.Component<IProps> {

    public static Title = Title;
    public static Content = Content;

    render() {
        return (
            <div className={"container-fluid d-flex h-100 flex-column " + this.props.className } style={this.props.style ? {...blockStyle,...this.props.style} : blockStyle}>
                {this.props.children}
            </div>
        );
    }

}

// Convience Components

interface ITitleProps {
    className?: string;
    style?: CSSProperties;
    children?: React.ReactNode;
    title?: string;
    subtitle?: string;
    validator?: IValidator;
    editable?: boolean;
    subtitleHref?: string;
    onEdit?: (text: string, valid: boolean) => void;
}

function Title(props: ITitleProps) {

    return (
        <div className={"block-header"} style={headerStyle}>
            <div className={props.className} style={props.style}>
                <h2>{props.editable ? <EditableField text={props.title} style={{color:'white'}} plaintext onEdit={(t,v) => {props.onEdit?.(t,v)}} validator={props.validator} /> : props.title}</h2>
                <h3>{props.subtitleHref ? <Link to={props.subtitleHref}><a>{props.subtitle}</a></Link> : props.subtitle}</h3>
                {props.children}
            </div>
        </div>
    );

}

interface IContentProps {
    className?: string;
    style?: CSSProperties;
    children?: React.ReactNode;
    grow?: boolean;
}

function Content(props: IContentProps) {
    var className = "page-container ";
    if (props.grow) className += "flex-grow-1 ";

    return (
        <div className={className + props.className} style={props.grow ? { ...growStyle, ...props.style } : props.style }>
            {props.children}
        </div>
    );
    
}

// Style

const blockStyle: CSSProperties = {
    margin:'16px 0px',
    padding:'0px',
    color:'white'
}

const headerStyle: CSSProperties = {
    width:'100%',
    display:'inline-block'
}

const growStyle: CSSProperties = {
    marginBottom:'32px'
}