import md5 from 'crypto-js/md5';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { ComponentType } from 'react';
import { Location, NavigateFunction, Params, useLocation, useNavigate, useParams } from 'react-router-dom';
import { USERS_COLLECTION } from '../controllers/datastore/Datastore';

//************************************************************************************************ 
//*  Helper Functions
//************************************************************************************************/

/**
 * Get a random integer from min to max
 * @param min start of range
 * @param max end of range
 * @returns random int from min to max
 */
export function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.round(Math.random() * (max - min) + min);
}

/**
 * Makes a Firebase response pretty for display
 * @param log string log from firebase
 * @returns pretty log
 */
export function prettyFirebaseLog(log: string) {
    var text = "";
    if (log) {
        text = log.replace(/(Firebase|firebase):/g, "")
            .replace(/Error|error/g, "")
            .replace(/\(.+\/|\)/g, "")
            .replace(/-/g, " ")
            .replace(/.$/g, "")
            .trim();
    }
    return text.charAt(0).toUpperCase() + text.slice(1);
}

/**
 * Shortens text to fit in display, appends "..." after trimLength
 * @param text 
 * @param trimLength 
 * @returns 
 */
export function trimDisplayText(text: string, trimLength: number) {
    if (text && text.length > trimLength) {
        text = text.trim().slice(0, trimLength - 3);
        text = text + "...";
    }
    return text;
}

export function getGravatarHash(email: string) {
    return md5(email.toLowerCase().trim());
}

export function isDevEnv(): boolean {
    return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
}

export function isTestEnv(): boolean {
    return process.env.NODE_ENV === 'test';
}

export function isProdEnv(): boolean {
    return process.env.NODE_ENV === 'production';
}

export async function getUserDisplayName(uuid: string) {
    const db = getFirestore();
    const userDoc = doc(db, USERS_COLLECTION, uuid);
    const user = await getDoc(userDoc);
    const data = user.data();
    if (data && data.username.trim() !== "") {
        return data.username.trim();
    } else {
        return uuid;
    }
}

export interface RouterProps {
    navigate: NavigateFunction;
    readonly params: Params<string>;
    location: Location;
}

export type WithRouterProps<T> = T & RouterProps;
type OmitRouter<T> = Omit<T, keyof RouterProps>;

export function withRouter<T>(Component: ComponentType<OmitRouter<T> & RouterProps>) {
    return (props: OmitRouter<T>) => {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();
        return (<Component location={location} navigate = { navigate }  params = { params } {...props } />);
    };
}