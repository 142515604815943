import { QueryConstraint } from 'firebase/firestore';
import DatastoreKey from '../../model/datastore/DatastoreKey';
import { Storable } from '../../model/Storable';
import CloudDatastore from './CloudDatastore';

//************************************************************************************************ 
//*  Key Constants
//************************************************************************************************/

const INDEX_KEY = ":Index";

// System
// ******************** Local Storage Keys ********************
export const REMEMBER_LOGIN_EMAIL = "rememberLoginEmail";
export const REMEMBER_LOGIN_EMAIL_CHECKED = "rememberLogin";
export const ACCEPT_PRIVACY_NOTICE = "acceptPrivacy";

// ******************** Session Storage Keys ********************
export const NEW_SESSION = 'new';

// ******************** Cloud Storage Keys ********************
export const SYSTEM_CATEGORY_KEY = "@System:";
export const SYSTEM_LOOT_COLLECTION_KEY = SYSTEM_CATEGORY_KEY+"Loot";
export const SYSTEM_LOOT_TABLE_COLLECTION_KEY = SYSTEM_CATEGORY_KEY+"LootTable";

// Loot
export const LOOT_CATEGORY_KEY = "@Loot:";

export const LOOT_TABLE_COLLECTION_KEY = LOOT_CATEGORY_KEY+"LootTable";
export const LOOT_TABLE_INDEX_KEY = LOOT_TABLE_COLLECTION_KEY + INDEX_KEY;

export const LOOT_COLLECTION_KEY = LOOT_CATEGORY_KEY+"Loot";
export const LOOT_INDEX_KEY = LOOT_COLLECTION_KEY + INDEX_KEY;

export const LOOT_CONTAINER_COLLECTION_KEY = LOOT_CATEGORY_KEY+"LootContainer"
export const LOOT_CONTAINER_INDEX_KEY  = LOOT_CONTAINER_COLLECTION_KEY + INDEX_KEY;

// Other
export const NOTE_TEXT_KEY = "NoteText";
export const USERS_COLLECTION = "users";

//************************************************************************************************ 
//* DataStore Factory
//************************************************************************************************/

/**
 * Datastore Factory Function
 */
export function getDatastore() {
    return new CloudDatastore() as Datastore;
}

//************************************************************************************************ 
//* DataStore Types
//************************************************************************************************/

export type Callback = (data: any) => void
/**
 * asdf
 * 
 */
export type Index = {key: DatastoreKey, id: string}

//************************************************************************************************ 
//* DataStore Interface
//************************************************************************************************/

/**
 *  Storage for Object with Storable \<Interface\>
 */
export interface Datastore {

    /**
     * 
     * @param storable 
     * @param callback 
     */
    set(storable: Storable): Promise<void>;

    /**
     * 
     * @param key 
     * @param includeReferences If true, resolves object references
     * @param callback 
     */
    get(key: DatastoreKey, includeReferences?: boolean): Promise<Storable | undefined>;

    /**
     * 
     * @param key 
     * @param query 
     */
    getAll(key: DatastoreKey, queryConstraints?: QueryConstraint): Promise<Storable[]>;

    /**
     * 
     * @param storable 
     * @param callback 
     */
    remove(storable: Storable): Promise<void>;

}