import { doc, getDoc, getFirestore, setDoc, Timestamp } from "firebase/firestore";
import React, { CSSProperties } from 'react';
import { SiGravatar } from 'react-icons/si';
import { connect, ConnectedProps } from "react-redux";
import ContentBlock from '../../components/system/ContentBlock';
import EditableField from '../../components/system/EditableField';
import ErrorAlert from "../../components/system/ErrorAlert";
import LoadingSpinner from '../../components/system/LoadingSpinner';
import PersistanceHeader from "../../components/system/PersistanceHeader";
import { USERS_COLLECTION } from "../../controllers/datastore/Datastore";
import { setUser } from "../../controllers/redux/reducers/UserReducer";
import { RootState } from "../../controllers/redux/Store";
import { TextValidator } from "../../controllers/validators/CommonValidators";
import { getGravatarHash, withRouter, WithRouterProps } from '../../utils/AppUtilities';
import { COLOR, ROUTE, TEXT, TITLE } from '../../utils/Constants';
import { logError } from '../../utils/Logger';

//************************************************************************************************ 
//*  State & Props
//************************************************************************************************/
interface IProps extends PropsFromRedux {}

interface IState {
    loading: boolean;
    userData: any;
    isDirty: boolean;
    showError: boolean;
}

//************************************************************************************************ 
//*  Redux
//************************************************************************************************/
const mapState = (state: RootState) => ({
    user: state.userState.user
});

const mapDispatch = {
    setUser: setUser
}

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

//************************************************************************************************ 
//*  Component
//************************************************************************************************/
class ProfilePage extends React.Component<WithRouterProps<IProps>, IState> {

    CLASSNAME = this.constructor.name;

    TextValidator = new TextValidator();

    constructor(props: WithRouterProps<IProps>) {
        super(props);
        this.state = {
            userData:{},
            loading:true,
            showError:false,
            isDirty:false
        }
        document.title = TITLE.USER_PROFILE;
    }

    componentDidMount(){
        if(this.props.user) this.loadProfile();
        else this.props.navigate(ROUTE.LOGIN)
    }

    componentDidUpdate(prevProps: Readonly<WithRouterProps<IProps>>, prevState: Readonly<IState>, snapshot?: any): void {
        if(prevProps.user !== this.props.user) {
            if(this.props.user) this.loadProfile();
            else this.props.navigate(ROUTE.LOGIN)
        }
    }

    loadProfile(){
        const db = getFirestore();
        const user = this.props.user;
        this.setState({loading:true});
        if(user){
            // logged in
            // load data from firestore
            try {
                const userDoc = doc(db, USERS_COLLECTION, user.uid);
                getDoc(userDoc).then((doc) => {
                    // on success
                    this.setState({loading: false, userData: doc.data() ? doc.data() : {}, isDirty:false});
                },
                () => {
                    // on error
                    this.setState({loading: false, showError: true, isDirty: false });
                });
            } catch(e: any) {
                logError(this.CLASSNAME+" - loadProfile", e);
                this.setState({loading: false, showError: true, isDirty:false });
            }
        } else {
            // logged out
            this.setState({loading:false, isDirty:false});
        }
    }

    onEditUsername(data: string, valid: boolean){
        if(valid){
            const userData = this.state.userData;
            userData.username = data;
            this.setState({isDirty:true, userData:userData});
        }
    }

    onEditFirstName(data: string, valid: boolean){
        console.log(this.state.userData)
        if(valid){
            const userData = this.state.userData;
            userData.firstName = data;
            this.setState({isDirty:true, userData:userData});
        }
    }

    onEditLastName(data: string, valid: boolean){
        if(valid){
            const userData = this.state.userData;
            userData.lastName = data;
            this.setState({isDirty:true, userData:userData});
        }
    }

    onEditGender(data: string, valid: boolean){
        if(valid){
            const userData = this.state.userData;
            userData.gender = data;
            this.setState({isDirty:true, userData:userData});
        }
    }

    onEditPronouns(data: string, valid: boolean){
        if(valid){
            const userData = this.state.userData;
            userData.pronouns = data;
            this.setState({isDirty:true, userData:userData});
        }
    }

    onSubmit(){
        const db = getFirestore();
        const user = this.props.user;
        if(user){
            try {
                const userDoc = doc(db, USERS_COLLECTION, user.uid);
                const data = this.state.userData;
                setDoc(userDoc,{
                    username: data.username ? data.username.trim() : "",
                    firstName: data.firstName ? data.firstName.trim() : "",
                    lastName: data.lastName ? data.lastName.trim() : "",
                    gender: data.gender ? data.gender : "",
                    pronouns: data.pronouns ? data.pronouns : "",
                    lastUpdate: Timestamp.fromDate(new Date())
                },{merge:true}).then(() => {
                    this.loadProfile();
                });
            } catch (e: any) {
                logError(this.CLASSNAME+" - onSubmit", e);
            }
        }
    }

    onRevert(){
        this.loadProfile();
    }

    render() {
        const email = this.props.user ? this.props.user.email : "";
        const emailHash = email ? getGravatarHash(email) : "";

        return (
            <div className="route-page">
            { this.state.loading ? <LoadingSpinner /> : null }
            { this.state.showError ? <ErrorAlert /> : null }
            { !this.state.loading && !this.state.showError ?
                <div>
                    <ContentBlock>
                        <ContentBlock.Title title="User Profile" />
                        <ContentBlock.Content>
                            <div className="row">
                                <div className="col-md-4 mt-4" style={{textAlign:'center'}}>
                                    <img src={"https://www.gravatar.com/avatar/"+emailHash+"?s=256"} style={avatarStyle} alt="Profile Avatar" />
                                    <h3 className="m-2">Powered by Gravatar <SiGravatar className="icon" style={{color:COLOR.GRAVATAR_BLUE}} /> </h3>
                                </div>
                                <div className="col-md-7 mt-4 mb-4">
                                    <EditableField className="mb-2" text={this.state.userData?.username} onEdit={(d,v) => {this.onEditUsername(d,v)}} validator={this.TextValidator} >Display Name:</EditableField>
                                    <EditableField className="mb-2" text={email ? email : ""} readOnly >Email:</EditableField>
                                    <EditableField className="mb-2" text={this.state.userData?.firstName} onEdit={(d,v) => {this.onEditFirstName(d,v)}} validator={this.TextValidator} >First Name:</EditableField>
                                    <EditableField className="mb-2" text={this.state.userData?.lastName} onEdit={(d,v) => {this.onEditLastName(d,v)}} validator={this.TextValidator} >Last Name:</EditableField>
                                    <div className="my-5"></div>
                                    <EditableField className="mb-2 a-dropdown" dropdown dropdownValues={TEXT.GENDER_IDENTITY} text={this.state.userData?.gender} onEdit={(d,v) => {this.onEditGender(d,v)}} >Gender:</EditableField>
                                    <EditableField className="mb-2 a-dropdown" dropdown dropdownValues={TEXT.GENDER_PRONOUNS} text={this.state.userData?.pronouns} onEdit={(d,v) => {this.onEditPronouns(d,v)}} >Pronouns:</EditableField>
                                </div>
                                <div className="col-12">
                                    <PersistanceHeader isDirty={this.state.isDirty} onSubmit={() => {this.onSubmit()}} onRevert={() => {this.onRevert()}} />
                                </div>
                            </div>
                        </ContentBlock.Content>
                    </ContentBlock>
                </div>
            : null }
            </div>
        );
    }

}

const avatarStyle: CSSProperties = {
    borderRadius:'50%'
}

export default connector(withRouter<IProps>(ProfilePage));