import React from 'react';
import { Alert } from 'react-bootstrap';
import { FaExclamationTriangle } from 'react-icons/fa';

interface IProps { }

interface IState { }

export default class ErrorAlert extends React.Component<IProps, IState> {

    render() {
        return (
            <div>
                <Alert variant="danger text-center">
                    <FaExclamationTriangle className="icon" /> There was a problem
                </Alert>
            </div>
        );
    }

}