import React, { CSSProperties } from 'react';
import { Form } from 'react-bootstrap';
import { FaTimesCircle } from 'react-icons/fa';

interface IProps {
    className?: string;
    style?: CSSProperties;
    onChange: (event: any) => void;
}

interface IState {
    text: string;
}

export default class SearchInput extends React.Component<IProps, IState> {

    constructor(props: IProps){
        super(props)
        this.state = {
            text:""
        }
    }

    onChange(event: any){
        this.setState({text:event.target.value})
        this.props.onChange(event);
    }

    onClear(event: any){
        this.setState({text:""})
        this.props.onChange(undefined);
    }

    render() {
        return (
            <Form className={this.props.className} style={{...this.props.style,...{position:'relative'}}}>
                <Form.Control style={{height:'36px'}} placeholder="Search" value={this.state.text} onChange={(e) => {this.onChange(e)}} />
                { this.state.text ? <FaTimesCircle className='icon' style={iconStyle} onClick={(e) => {this.onClear(e)}} /> : null }
            </Form>
        );
    }

}

const iconStyle: CSSProperties = {
    position:'absolute',
    right:'24px',
    top:'50%',
    transform:'translate(0%, -50%)',
    cursor:'pointer'
}
