import WeightSelector from '../../controllers/WeightSelector';
import INameable from './INameable';

//************************************************************************************************ 
//*  Selector Factory
//************************************************************************************************/
export default function getSelector() {
     return new WeightSelector() as ISelector;
}

//************************************************************************************************ 
//*  Selector Interfaces
//************************************************************************************************/
export interface ISelector {

    select(table: any): any[];

}

export interface ISelectable extends INameable {

    minQuantity: number;
    maxQuantity: number;

    replacement: boolean;

    getItems(): any[];

    getTotalWeight(): number;

}