import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/types';
import { CONFIG } from '../../utils/Constants';

interface IProps {
    tooltip: string;
    placement?: Placement;
}

interface IState {

}

export default class CustomTooltip extends React.Component<IProps, IState> {

    render() {
        return (
            <OverlayTrigger
                key={"Tooltip-"+this.props.tooltip}
                placement={this.props.placement || "auto"} 
                delay={CONFIG.TOOLTIP.DELAY} 
                overlay={<Tooltip>{this.props.tooltip}</Tooltip>} 
            >  
                {/* @ts-ignore */}
                {this.props.children}
            </OverlayTrigger>
        );
    }

}