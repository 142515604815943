import React from 'react';
import ReactPlayer from 'react-player/youtube';
import Prompt, { IProps as PromptProps } from './Prompt';
import PromptOverlay from './PromptOverlay';


interface IProps extends PromptProps{
}

interface IState {

}

export default class VideoPrompt extends React.Component<IProps, IState> {

    render() {

        const style = {
            maxWidth:'1280px',
            height:'720px'
        }
        
        return (
            <PromptOverlay onClickClose={this.props.onClickClose}>
                <Prompt style={style} {...this.props}>
                    <ReactPlayer width={'100%'} height={'100%'} url='https://www.youtube.com/watch?v=d4fxZWPZcdo' />
                </Prompt>
            </PromptOverlay>
        );
    }

}