import { v4 as uuidv4 } from 'uuid';

export default abstract class MetricMetadata {

    creationDate: Date;
    /** Globally Unique Id */
    UUID: string;

    constructor(){
        this.creationDate  = new Date();
        this.UUID = uuidv4();
    }

}