import React from 'react';
import PromptOverlay from '../../components/system/prompts/PromptOverlay';
import SearchSortFilterBar from '../../components/system/SearchSortFilterBar';
import Sorter from '../../components/system/Sorter';
import Loot from '../../model/loot/Loot';
import APagination, { MAX_ITEM_PER_PAGE } from '../system/APagination';
import { ActionMenuRenderOptions } from '../system/buttons/ActionButton';
import LootDetailsEdit from './LootDetailsEdit';
import LootItemCard from './LootItemCard';

interface IProps {
    data: Loot[];
    menuOptions?: ActionMenuRenderOptions[];
    /** on loot card click */
    onClick?: (event: any, item: Loot) => void;
    /** Overide default menu item rendering */
    onRenderMenuItem?: (data: Loot, option: ActionMenuRenderOptions) => React.ReactNode;
}

interface IState {
    clickedLoot?: Loot;
    showClickedLoot: boolean;
    reverseSort: boolean;
    searchText: string;
    page: number;
}

export default class LootList extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            clickedLoot: undefined,
            showClickedLoot:false,
            reverseSort: false,
            searchText:"",
            page: 1
        }
    }

    // Search/Sort/Filter

    onSearch(text: string){
        this.setState({searchText:text, page:1});
    }

    onSortAlpha(sortAlphaUp: boolean){
        this.setState({reverseSort:!sortAlphaUp});
    }

    // Render

    filter(items: Loot[]){
        var results = items;
        if(!results) return results; // skip if null
        // search text
        const text = this.state.searchText;
        results = results.filter((loot) => {
            const name = loot.name.toLowerCase().trim();
            const description = loot.description.toLowerCase().trim();
            const category = loot.category.toLowerCase().trim();
            const txt = text.toLowerCase().trim();
            return name.includes(txt) || description.includes(txt) || category.includes(txt);
        });
        return results;
    }
    
    getLootComponents(data: Loot[]){
        const itemEl = [];        
        for(let y=0; y<data.length;y++){
            const loot = Loot.toClass(data[y]);
            const key = loot.name + loot.creationDate + y;
            itemEl.push(
                < LootItemCard
                    className="col-12 col-md-6 col-lg-4"
                    data={loot}
                    key={key}
                    sortKey={key}
                    menuOptions={this.props.menuOptions}
                    onRenderMenuItem={this.props.onRenderMenuItem}
                    onClick={(e,d) => {
                        if (this.props.onClick) this.props.onClick(e,d);
                        else this.setState({showClickedLoot:true,clickedLoot:d})
                    }}
                />
            );
        }
        return itemEl;
    }

    render() {
        const filteredLoot = this.filter(this.props.data);
        const idx = ((MAX_ITEM_PER_PAGE)*this.state.page) - (MAX_ITEM_PER_PAGE);
        const pagedLoot = filteredLoot.slice(idx,idx+MAX_ITEM_PER_PAGE);

        return (
            <div className="row" style={{margin:0, padding:0}}>
                { this.state.showClickedLoot && this.state.clickedLoot ?
                    <PromptOverlay onClickClose={() => {this.setState({showClickedLoot:false,clickedLoot:undefined})}}>
                        <LootDetailsEdit loot={this.state.clickedLoot} prompt /> 
                    </PromptOverlay> 
                : null }
                { this.props.data?.length > 6 ? <SearchSortFilterBar onSearch={(text) => {this.onSearch(text)}} onSortAlpha={(sortAlphaUp) => {this.onSortAlpha(sortAlphaUp)}} /> : null }
                <Sorter by="sortKey" reverse={this.state.reverseSort}>
                    {this.getLootComponents(pagedLoot)}
                </Sorter>
                <div>
                    <APagination numPages={filteredLoot.length/MAX_ITEM_PER_PAGE} onClickPage={(e, page) => {this.setState({page})}} />
                </div>
            </div>
        );
    }

}