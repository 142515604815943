import React from 'react';
import { FaCoffee, FaDiscord, FaReddit, FaTwitter } from 'react-icons/fa';
import { MdCopyright } from 'react-icons/md';
import { Link } from 'react-router-dom';
import BuyMeACoffeeButton from '../../components/system/buttons/BuyMeACoffeeButton';
import { ROUTE, URL } from '../../utils/Constants';

interface IProps {

}

interface IState {
    year: number;
}

export default class Footer extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            year: 0
        }
    }

    componentDidMount() {
        this.setState({ year: new Date().getFullYear() })
    }

    render() {
        return (
            <div className="footer container-fluid p-0 m-0 d-flex h-100 flex-column">
                <div className="px-4">
                    <BuyMeACoffeeButton />
                </div>
                <div className="flex-grow-1" style={style}>
                    <div className="footer-text row">
                        <div className="col-xs-12 col-sm-2">
                            <MdCopyright className="icon" /> 2021 - {this.state.year} Alex Salerno
                        </div>
                        <div className="col-xs-12 col-sm-8">
                            <div className="row">
                                <span className="col-xs-12 col-sm-2"><a href={URL.ROADMAP} style={linkStyle} target="_blank" rel="noreferrer noopener">Development Roadmap</a></span>
                                <span className="col-xs-12 col-sm-2"><a href={URL.BLOG} style={linkStyle} target="_blank" rel="noreferrer noopener">Blog</a></span>
                                <span className="col-xs-12 col-sm-2"><a href={URL.FEEDBACK} style={linkStyle} target="_blank" rel="noreferrer noopener">Send Feedback</a></span>
                                <span className="col-xs-12 col-sm-2"><Link to={ROUTE.FOUNDERS}><a style={linkStyle} target="_blank" rel="noreferrer noopener">Founders</a></Link></span>
                                <span className="col-xs-12 col-sm-2"><Link to={ROUTE.PRIVACY}><a style={linkStyle} target="_blank" rel="noreferrer noopener">Privacy</a></Link></span>
                                <span className="col-xs-12 col-sm-2"><Link to={ROUTE.TOS}><a style={linkStyle} target="_blank" rel="noreferrer noopener">Terms & Conditions</a></Link></span>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-2">
                            <a href={URL.DONATE} style={linkStyle} target="_blank" rel="noreferrer noopener"><FaCoffee className="me-3 mb-1" size={30} /></a>
                            <a href={URL.TWITTER} style={linkStyle} target="_blank" rel="noreferrer noopener"><FaTwitter className="me-3 mb-1" size={30} /></a>
                            <a href={URL.DISCORD} style={linkStyle} target="_blank" rel="noreferrer noopener"><FaDiscord className="me-3 mb-1" size={30} /></a>
                            <a href={URL.REDDIT} style={linkStyle} target="_blank" rel="noreferrer noopener"><FaReddit className="me-3 mb-1" size={30} /></a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

const style = {
    backgroundColor:'#212529',
    color: 'white'
}

const linkStyle = {
    color: "white"
}