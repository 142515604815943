import React, { CSSProperties } from 'react';

interface IProps {

}

interface IState {
    
}

/**
 * 
 */
export default class ContentBar extends React.Component<IProps, IState> {

    // Sub-Components
    static Content: Function;
    static ContentLeft: Function;
    static ContentRight: Function;

    render() {
        return (
            <div className="container-fluid p-0 mb-2">
                <div className='row justify-content-between align-items-center m-0'>
                    {this.props.children}
                </div>
            </div>
        );
    }

}

//************************************************************************************************ 
//*  Sub-Components
//************************************************************************************************/

interface IContentProps {
    children?: React.ReactNode;
}

const columns = 'col-12 col-md';

const style: CSSProperties = {
    
}

ContentBar.Content = function (props: IContentProps) {
    return (
        <div className={columns}>
            <div className='row justify-content-start' style={style}>
                {props.children}
            </div>
        </div>
    );
}

ContentBar.ContentLeft = ContentBar.Content;

ContentBar.ContentRight = function (props: IContentProps) {
    return (
        <div className={columns}>
            <div className="row justify-content-end" style={style}>
                {props.children}
            </div>
        </div>
    );
}