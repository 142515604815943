import { sendPasswordResetEmail } from '@firebase/auth';
import React from 'react';
import { Alert, Button, FloatingLabel, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { prettyFirebaseLog, withRouter, WithRouterProps } from '../../utils/AppUtilities';
import { COLOR, EVENT, ROUTE } from '../../utils/Constants';
import { auth } from '../../utils/FirebaseUtils';
import { log, logAnalyticsEvent, logError } from '../../utils/Logger';
import Prompt from '../system/prompts/Prompt';

interface IProps {}

interface IState {
    email: string;
    success: boolean;
    errorMsg: string;
}

class ForgetPasswordForm extends React.Component<WithRouterProps<IProps>, IState> {
    
    CLASSNAME = this.constructor.name;

    constructor(props: WithRouterProps<IProps>) {
        super(props);
        this.state = {
            email:"",
            success:false,
            errorMsg:""
        }
    }

    onEmailChange(event: any){
        this.setState({email:event.target.value})
    }

    resetPassword(event: any){
        const caller = this.CLASSNAME + " - resetPassword";
        event.preventDefault();
        sendPasswordResetEmail(auth, this.state.email).then(() => {
            this.setState({success:true,errorMsg:""});
            log(caller, "A reset password email has been sent");
            logAnalyticsEvent(EVENT.FORGOT_PASSWORD);
            toast('An email has been sent to your email with reset instructions');
            this.props.navigate(ROUTE.LOGIN);
        }).catch((e) => {
            this.setState({errorMsg:e.message, success:false});
            logError(caller, e);
        });
    }

    render() {
        return (
            <Prompt title="Forgot Password">
                <Form>
                    <Form.Group className="mb-3">
                        <FloatingLabel label="Email Address">
                            <Form.Control style={style} type="email" placeholder="Enter email" value={this.state.email} onChange={(e) => {this.onEmailChange(e)}} />
                        </FloatingLabel>
                    </Form.Group>
                    {this.state.errorMsg.trim() !== "" ? <Alert variant="danger"> There was an issue: {prettyFirebaseLog(this.state.errorMsg)} </Alert> : null}
                    {this.state.success ? <Alert variant="success"> An email has been sent to {this.state.email} with reset instructions </Alert> : null}
                    <Button className="" variant="primary" onClick={(e) => {this.resetPassword(e)}}>
                        Reset Password
                    </Button>
                    <Link to={ROUTE.LOGIN}>
                        <Button style={{float:"right"}} variant="secondary" >
                            Login
                        </Button>
                    </Link>
                </Form>
            </Prompt>
        );
    }

}

const style = {
    backgroundColor: COLOR.PRIMARY_TAN, 
    borderColor: COLOR.SECONDARY_TAN
}

export default withRouter<IProps>(ForgetPasswordForm);