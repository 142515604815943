import React from 'react';
import ContentBlock from '../../components/system/ContentBlock';
import { TextValidator } from '../../controllers/validators/CommonValidators';
import IValidator from '../../model/interfaces/IValidator';
import Loot from '../../model/loot/Loot';
import LootTable from '../../model/loot/LootTable';
import { ComponentConfigurations } from '../../utils/CommonTypes';
import LootTableDetailsEdit from './LootTableDetailsEdit';
import LootTableDetailsView from './LootTableDetailsView';

interface IProps {
    table: LootTable;
    state: ComponentConfigurations;

    quantityValidator?: IValidator;
    invalidQuantity?: boolean;
    onEdit?: (event: any, table: LootTable) => void;
    onEditLoot?: (event: any, data: Loot) => void;
    onEditName?: (text: string, valid: boolean) => void;
    onDelete?: (event: any, table: LootTable) => void;
    onDeleteLoot?: (event: any, table: Loot) => void;
    onAddLoot?: (event: any, table: LootTable) => void;
    onAddNewLoot?: (event: any, table: LootTable) => void;
    onMinQuantityEdit?: (value: any, valid: boolean) => void;
    onMaxQuantityEdit?: (value: any, valid: boolean) => void;
    onVisibilityEdit?: (value: any, valid: boolean) => void;
    onRevert?: (event: any) => void;
    onSubmit?: (event: any) => void;
    onSelectLoot?: () => void;
}

interface IState {
    isDirty: boolean;
    minQuantity: number;
    maxQuantity: number;
}

/**
 * Loot Table Detail Component
 */
export default class LootTableView extends React.Component<IProps, IState> {

    lootTableNameValidator = new TextValidator();

    constructor(props: IProps) {
        super(props);

        const table = this.props.table;
        this.state = {
            isDirty: false,
            minQuantity: table.minQuantity,
            maxQuantity: table.maxQuantity
        }

    }

    //************************************************************************************************ 
    //*  Event Handlers
    //************************************************************************************************/

    onEdit(a:any, b:any, func: any){
        this.setState({isDirty:true});
        func(a,b);
    }

    onSave(func: any){
        this.setState({isDirty:false});
        func();
    }

    //************************************************************************************************ 
    //*  Render
    //************************************************************************************************/

    render() {
        const table = this.props.table;
        return (
                <ContentBlock>
                <ContentBlock.Title title={table.name} subtitle={this.props.state.includes("Edit") ? "Edit Loot Table" : "Loot Table"} editable={this.props.state.includes("Edit")} onEdit={(t: string, v: boolean) => { if (this.props.onEditName) this.onEdit(t,v,this.props.onEditName)}} validator={this.lootTableNameValidator} />
                    <ContentBlock.Content>
                        { this.props.state.includes("View") ? <LootTableDetailsView table={table} /> : 
                            <LootTableDetailsEdit 
                                table={table}
                                state={this.props.state}
                                isDirty={this.state.isDirty}
                                onAddNewLoot={(e, t) => { if (this.props.onAddNewLoot) this.onEdit(e, t, this.props.onAddNewLoot) }}
                                onAddLoot={(e, t) => { if (this.props.onAddLoot) this.onEdit(e, t, this.props.onAddLoot) }}
                                onEditLoot={(e, d) => { if (this.props.onEditLoot) this.props.onEditLoot(e, d) }}
                                onDeleteLoot={(e, d) => { if (this.props.onDeleteLoot) this.props.onDeleteLoot(e, d) }}
                                onMinQuantityEdit={(d, v) => { this.onEdit(d, v, this.props.onMinQuantityEdit) }}
                                onMaxQuantityEdit={(d, v) => { this.onEdit(d, v, this.props.onMaxQuantityEdit) }}
                                onVisibilityEdit={(d, v) => { this.onEdit(d, v, this.props.onVisibilityEdit) }}
                                onRevert={() => { this.onSave(this.props.onRevert) }}
                                onSubmit={() => { this.onSave(this.props.onSubmit) }}
                                quantityValidator={this.props.quantityValidator}
                                invalidQuantity={this.props.invalidQuantity}
                            />
                        }
                    </ContentBlock.Content>
                </ContentBlock>
        );
    }

}