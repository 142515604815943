import React from 'react';
import { FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa';
import CustomButton from '../../components/system/buttons/CustomButton';
import ContentBar from './ContentBar';
import SearchInput from './SearchInput';

interface IProps {
    onSortAlpha: (sortAlphaUp: boolean) => void;
    onSearch: (text: string) => void;
}

interface IState {
    sortAlphaUp: boolean;
}

export default class LootListBar extends React.Component<IProps, IState> {

    constructor(props: IProps){
        super(props)
        this.state = {
            sortAlphaUp: true
        }
    }

    onSearch(event: any){
        var text = "";
        if(event) text = event.target.value;
        this.props.onSearch(text);
    }

    onSortAlpha(){
        const sortAlpha = this.state.sortAlphaUp;
        this.setState({sortAlphaUp:!sortAlpha});
        this.props.onSortAlpha(!sortAlpha);
    }

    render() {
        const buttonClasses = "col-3 col-md-2 col-lg-1 mb-2";
        return (
            <ContentBar>
                <ContentBar.ContentLeft>
                    <SearchInput className="col-md-6 mb-2" style={{height:'36px'}} onChange={(e) => {this.onSearch(e)}} />
                    { this.state.sortAlphaUp ? 
                        <CustomButton className={buttonClasses} style={buttonStyle} variant="secondary" tooltip='Sort Ascending' onClick={() => {this.onSortAlpha()}}><FaSortAlphaDown className='icon'/></CustomButton> : 
                        <CustomButton className={buttonClasses} style={buttonStyle} variant="secondary" tooltip='Sort Descending' onClick={() => {this.onSortAlpha()}}><FaSortAlphaUp className='icon'/></CustomButton>
                    }
                </ContentBar.ContentLeft>
                <ContentBar.ContentLeft>
                    
                </ContentBar.ContentLeft>
            </ContentBar>
        );
    }

}

const buttonStyle = {
    marginLeft:'12px'
}
