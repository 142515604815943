import React, { CSSProperties } from 'react';
import { Badge } from 'react-bootstrap';

interface IProps {
    className?: string;
    style?: CSSProperties;
    rarity: any;
}

interface IState {

}

export default class RarityView extends React.Component<IProps, IState> {

    render() {
        return (
            <span className={this.props.className} style={this.props.style} >
                <Badge style={style} bg="junk">{this.props.rarity.junk}</Badge> <Badge style={style} bg="common">{this.props.rarity.common}</Badge> <Badge style={style} bg="uncommon">{this.props.rarity.uncommon}</Badge> <Badge style={style} bg="rare">{this.props.rarity.rare}</Badge> <Badge style={style} bg="epic">{this.props.rarity.epic}</Badge> <Badge style={style} bg="legendary">{this.props.rarity.legendary}</Badge>
            </span>
        );
    }

}

const style= {
    minWidth:'2.5em'
}