import { EventParams, getAnalytics, logEvent } from 'firebase/analytics';

/**
 * Log an infromational message
 * 
 * Format: "-> [\<timestamp\>] | \<calling method\> | msg extMsg"
 * @param msg Message to show
 * @param extMsg If extended messages are enabled, show this too. Used for optional and/or large message.
 */
export function log(caller: string, msg: string, extMsg?: string) {
    if (extMsg) console.log(buildHeader(caller) + msg + " " + extMsg)
    else console.log(buildHeader(caller) + msg)
}

/**
* Log an infromational message, also sends log to Analytics Service
*
* @param msg Message to show
* @param extMsg If extended messages are enabled, show this too. Used for optional and/or large message.
*/
export function logAnalyticsEvent(eventName: string, parms?: EventParams) {
    const analytics = getAnalytics();
    var event = eventName.replaceAll(/[ |-]/g,"_").trim().toLowerCase();
    logEvent(analytics, event, parms);
    console.log(buildHeader("Analytic Event") + eventName);
}

/**
* Log an warning message
* 
* Format: "-> [\<timestamp\>] | \<calling method\> | msg"
* @param msg Message to show
* @param extMsg If extended messages are enabled, show this too. Used for optional and/or large message.
*/
export function logWarning(caller: string, msg: string) {
    console.warn(buildHeader(caller) + msg)
}

/**
* Log an error message, also sends log to Analytics Service
* 
* Format: "-> [\<timestamp\>] | \<calling method\> | msg"
* @param msg Message to show
* @param extMsg If extended messages are enabled, show this too. Used for optional and/or large message.
*/
export function logError(caller: string, error: Error) {
    const message = buildHeader(caller) + error.name + " " + error.message;
    const analytics = getAnalytics();
    // send analytics event
    const event = "error_" + caller + "_" + error.name;
    logAnalyticsEvent(event);
    // log error
    console.error(message);
}

export type Error = {
    name: string;
    message: string;
    code: string;
}

function buildHeader(caller: string) {
    let timestamp = new Date();
    let header = "\n-> [" + timestamp + "]  | " + caller + " | ";
    return header;
}

export function nuke() {
    console.log("                                     ____")
    console.log("                             __,-~~/~    `---.")
    console.log("                           _/_,---(      ,    )")
    console.log("                       __ /        <    /   )  \___")
    console.log("        - ------===;;;'====------------------===;;;===----- -  -")
    console.log('                          \/  ~"~"~"~"~"~\~"~)~"/')
    console.log("                          (_ (   \  (     >    \)")
    console.log("                           \_( _ <         >_>'")
    console.log("                              ~ `-i' ::>|--'")
    console.log("                                  I;|.|.|")
    console.log("                                  I;|.|.|")
    console.log("                                  I;|.|.|")
    console.log("                                 <|i::|i|`.")
    console.log("                      /|  ( ' (  (` ^''`-' ') i; ) ' )|\\ ")
    console.log("                     {'^ (   ( ''(` ^''`-' ') ^')'' )^ `}")
}