import { plainToInstance } from 'class-transformer';
import { LOOT_TABLE_COLLECTION_KEY } from '../../controllers/datastore/Datastore';
import { Storable, StorableReference } from '../../model/Storable';
import IPublicAccess, { PrivacyMode } from '../interfaces/IPublicAccess';
import { ISelectable } from '../interfaces/ISelector';
import Loot from './Loot';
import { Rarity } from './Rarity';

/**
 * todo comment
 */
export default class LootTable extends Storable implements ISelectable, IPublicAccess {

    // Selectable Variables
    maxQuantity: number = 1;
    minQuantity: number = 1;
    replacement: boolean = false;
    private totalWeight: number = 0;

    // public access
    privacy: PrivacyMode = "Public";

    //************************************************************************************************ 
    //*  Init
    //************************************************************************************************/

    constructor(name: string){
        super(name, LOOT_TABLE_COLLECTION_KEY);
    }
    

    //************************************************************************************************ 
    //*  Public Functions
    //************************************************************************************************/

    addLoot(loot: Loot){
        this.addReference(loot);
    }

    addAllLoot(loot: Loot[]){
        loot.forEach((item,idx) => {
            this.addLoot(item)
        });
    }

    removeLoot(loot: Loot){
        this.removeReferencable(new StorableReference(loot));
    }

    getAllLoot(): Loot[]{
        const references = this.getReferences();
        const lootPool: Loot[] = [];
        for(var i=0;i<references.length;i++){
            var loot = references[i].storable as Loot;
            //@ts-ignore
            if (loot) lootPool.push(Loot.toClass(loot));
        }
        return lootPool;
    }

    calculateTotalWeight(){
        const loot = this.getAllLoot();
        this.totalWeight = 0;
        for(var l of loot){
            this.totalWeight += l.rarity;
        }
    }

    toString(){
        return this.name;
    }

    getRarity(){
        let j = 0;
        let c = 0;
        let u = 0;
        let r = 0;
        let l = 0;
        let e = 0;
        const lootPool = this.getAllLoot();
        if (lootPool){
            for (let i = 0; i < lootPool.length;i++){
                let loot = lootPool[i];
                if(loot.rarity === Rarity.Junk) j++;
                if(loot.rarity === Rarity.Common) c++;
                if(loot.rarity === Rarity.Uncommon) u++;
                if(loot.rarity === Rarity.Rare) r++;
                if(loot.rarity === Rarity.Legendary) l++;
                if(loot.rarity === Rarity.Epic) e++;
            }
        }
        return {junk:j,common:c,uncommon:u,rare:r,legendary:l,epic:e}
    }

    static toClass(obj: Object): LootTable {
        return plainToInstance(LootTable, obj);
    }

    //************************************************************************************************ 
    //*  Selectable Impl
    //************************************************************************************************/

    getTotalWeight(){
        this.calculateTotalWeight();
        return this.totalWeight;
    }

    getItems() {
        return this.getAllLoot();
    };

}