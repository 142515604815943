import React, { CSSProperties } from 'react';
import { Button } from 'react-bootstrap';
import Prompt from './Prompt';
import PromptOverlay from './PromptOverlay';


interface IProps {
    title?: string;
    onClickClose?: (event: any) => void;
    onClickNo?: (event: any) => void;
    onClickYes?: (event: any) => void;
}

interface IState {

}

export default class ConfirmationPrompt extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
        }
    }

    componentDidMount(){

    }

    render() {
        return (
            <PromptOverlay hideClose>
                <Prompt onClickClose={this.props.onClickClose ? this.props.onClickClose : this.props.onClickNo}>
                    <div className="row">
                        <h4 className="col-11">{this.props.title ? this.props.title : "Are you sure?"}</h4>
                    </div>
                    <div className="row justify-content-between" style={buttonStyle}>
                        <Button className="col-md-4 mb-2" variant="primary" onClick={this.props.onClickYes}>Yes</Button>
                        <Button className="col-md-4 mb-2" variant="secondary" onClick={this.props.onClickNo}>No</Button>
                    </div>
                </Prompt>
            </PromptOverlay>
        );
    }

}

const buttonStyle: CSSProperties = {
    margin:'15px 5px -4px 5px'
}