import React from 'react';
import LootTable from '../../model/loot/LootTable';
import { getUserDisplayName } from '../../utils/AppUtilities';
import LootList from './LootList';

interface IProps {
    table: LootTable;
}

interface IState {
    ownerName: string;
}

/**
 * Loot Table Detail Component
 */
export default class LootTableDetailsView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            ownerName: ""
        }
    }

    componentDidMount(){
        // Get owner display name
        const table = this.props.table;
        if(table.OWNER_ID){
            getUserDisplayName(table.OWNER_ID).then((name) => {
                this.setState({ownerName:name});
            });
        } else {
            this.setState({ownerName:"Alderman RPG"})
        }
    }

    render() {
        const table = this.props.table;
        const loot = table.getItems();

        return (
            <div>
                {/* Loot Table Fields */}
                <div className="row">
                    { this.state.ownerName ? <h3>Created by {this.state.ownerName}</h3> : null }
                    <h5 className="col-md-10">Picks {table.minQuantity} to {table.maxQuantity} items</h5>
                </div>
                <hr />
                {/* Loot List */}
                <LootList
                    data={loot}
                />
            </div>
        );
    }

}

const buttonStyle = {
    display: 'inline-block',
    margin: '0',
    width: '32px',
    height: '32px'
}