import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import LootList from '../../components/loot/LootList';
import ActionButton, { ActionMenuRenderOptions } from '../../components/system/buttons/ActionButton';
import { AddButton } from '../../components/system/buttons/CommonButtons';
import ContentBlock from '../../components/system/ContentBlock';
import LoadingSpinner from '../../components/system/LoadingSpinner';
import ConfirmationPrompt from '../../components/system/prompts/ConfrimationPrompt';
import { getDatastore } from '../../controllers/datastore/Datastore';
import { setUser } from '../../controllers/redux/reducers/UserReducer';
import { RootState } from '../../controllers/redux/Store';
import Loot from '../../model/loot/Loot';
import { Rarity } from '../../model/loot/Rarity';
import { EVENT, ROUTE, TITLE } from '../../utils/Constants';
import { logAnalyticsEvent } from '../../utils/Logger';
import { getAllLoot } from '../../utils/LootHelper';

//************************************************************************************************ 
//*  State & Props
//************************************************************************************************/
interface IProps extends PropsFromRedux {}

interface IState {
    loot: Loot[];
    loading: boolean;
    confirmDelete: boolean;
    selectedLoot: Loot | undefined;
}

//************************************************************************************************ 
//*  Redux
//************************************************************************************************/
const mapState = (state: RootState) => ({
    user: state.userState.user
});

const mapDispatch = {
    setUser: setUser
}

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

//************************************************************************************************ 
//*  Component
//************************************************************************************************/
class LootPage extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            loot:[],
            loading: true,
            confirmDelete: false,
            selectedLoot: undefined
        }
        document.title = TITLE.LOOT;
    }

    componentDidMount(){
        this.loadData().then(() => {
            this.setState({loading:false});
        });
    }

    async loadData(){
        const loot = await getAllLoot();
        this.setState({loot:loot});
    }

    onEdit(event: any, loot: Loot){
        window.location.href=ROUTE.LOOT_EDIT+loot.UUID;
    }

    onDelete(event: any, loot: Loot){
        this.setState({selectedLoot:loot,confirmDelete:true});
    }

    onDeleteConfrimation(loot: Loot){
        const store = getDatastore();
        if(this.state.selectedLoot){
            store.remove(this.state.selectedLoot).then(()=>{
                logAnalyticsEvent(EVENT.DELETE_LOOT_INSTANCE);
                this.setState({confirmDelete:false, selectedLoot:undefined},() => {
                    this.loadData();
                });
            });
        }
    }

    onDeleteDeny(loot: Loot) {
        this.setState({confirmDelete:false});
    }

    onNewLoot(event: any) {
        event.preventDefault();
        // New Loot Table
        const loot = new Loot("New Loot", Rarity.Common);
        loot.store().then(() => {
            logAnalyticsEvent(EVENT.NEW_LOOT_INSTANCE);
            window.location.href = ROUTE.LOOT_EDIT + loot.UUID;
        });
    }

    // render

    getLootMenuOptions(){
        const options: ActionMenuRenderOptions[] = [
            {text:"Edit Loot", onClick:(e,d) => {this.onEdit(e,d)}},
            {text:"Delete Loot", onClick:(e,d) => {this.onDelete(e,d)}}
        ];
        return options;
    }

    renderMenuItem(data: Loot, option: ActionMenuRenderOptions){
        if(!data.SYSTEM_DATA){
            return <ActionButton.Menu text={option.text} onClick={(e: any) => {option.onClick(e,data)}} />;
        }
        return (<></>);
    }

    render() {
        return (
            <div className="route-page">
                { this.state.loading ? <LoadingSpinner /> : null }
                {this.state.confirmDelete ? <ConfirmationPrompt onClickYes={(e) => {this.onDeleteConfrimation(e)}} onClickNo={(e) => {this.onDeleteDeny(e)}} title={"Are you sure you would like to delete " + this.state.selectedLoot?.name + "?"} /> : null}
                <ContentBlock>
                    <ContentBlock.Title title="Loot">
                        <div className="row" style={{ margin: '8px 0px' }}>
                            <div className="col-md-10" />
                            <AddButton className="col-md-2" tooltip={this.props.user ? "Add New Loot" : "Requires user account"} onClick={(e) => { this.onNewLoot(e) }} disabled={this.props.user === null} lock={this.props.user === null}>Create Loot</AddButton>
                        </div>
                    </ContentBlock.Title>
                    <ContentBlock.Content>
                        {this.state.loot.length === 0 ? <h2 className="text-subdued" style={{ textAlign: 'center', margin: '15px' }}>All your base are belong to us</h2> : null}
                        <LootList
                            data={this.state.loot}
                            menuOptions={this.getLootMenuOptions()}
                            onRenderMenuItem={(d,o) => {return this.renderMenuItem(d,o)}} 
                        />
                    </ContentBlock.Content>
                </ContentBlock>
            </div>
        );
    }

}

export default connector(LootPage);