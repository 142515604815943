interface IProps {
    
}

export default function NothingHere(props: IProps) {
    
    return (
        <h2 style={{paddingLeft:"16px", paddingTop:"32px"}}>Whoops there is nothing here...</h2>
    );

}