import { getAuth, signInWithEmailAndPassword } from '@firebase/auth';
import { doc, getFirestore, setDoc, Timestamp } from 'firebase/firestore';
import React from 'react';
import { Alert, Button, FloatingLabel, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { REMEMBER_LOGIN_EMAIL, REMEMBER_LOGIN_EMAIL_CHECKED, USERS_COLLECTION } from '../../controllers/datastore/Datastore';
import { prettyFirebaseLog } from '../../utils/AppUtilities';
import { COLOR, EVENT, ROUTE } from '../../utils/Constants';
import { logAnalyticsEvent, logError } from '../../utils/Logger';

interface IProps {

}

interface IState {
    email: string;
    password: string;
    errorMsg: string;
    success: boolean;
    rememberMe: boolean;
}

export default class LoginForm extends React.Component<IProps, IState> {

    CLASSNAME = this.constructor.name;

    constructor(props: IProps) {
        super(props);
        this.state = {
            email:"",
            password:"",
            errorMsg:"",
            success:false,
            rememberMe:false
        }
    }

    componentDidMount(){
        // Init Remember Email
        const rm = localStorage.getItem(REMEMBER_LOGIN_EMAIL_CHECKED);
        if(rm) this.setState({rememberMe:(rm === 'true')});
        if(rm === 'true'){
            const email = localStorage.getItem(REMEMBER_LOGIN_EMAIL);
            this.setState({email:email || ""});
        }
    }

    loginUser(event: any){
        event.preventDefault();
        const auth = getAuth();
        // Remember Email
        if(this.state.rememberMe){
            localStorage.setItem(REMEMBER_LOGIN_EMAIL,this.state.email);
        }
        // Sign In
        signInWithEmailAndPassword(auth,this.state.email,this.state.password).then((credentials) => {
            this.setState({success:true,errorMsg:""});
            // log & notify
            const db = getFirestore();
            const userDoc = doc(db, USERS_COLLECTION, credentials.user.uid);
            setDoc(userDoc,{lastLogin:Timestamp.fromDate(new Date())},{merge:true});
            logAnalyticsEvent(EVENT.USER_LOGIN);
            toast("Login successful");
        }).catch((e) => {
            this.setState({errorMsg:e.message, success:false});
            logError(this.CLASSNAME+" - loginUser", e);
        });
    }

    onEmailChange(event: any){
        this.setState({email:event.target.value})
    }

    onPasswordChange(event: any){
        this.setState({password:event.target.value});
    }

    onRememberEmailChange(event: any){
        this.setState({rememberMe:!this.state.rememberMe}, () => {
            localStorage.setItem(REMEMBER_LOGIN_EMAIL_CHECKED, String(this.state.rememberMe));
        });
    }

    render() {
        return (
            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <FloatingLabel label="Email Address" style={{color:'black'}} >
                        <Form.Control style={style} type="email" placeholder="Enter email" value={this.state.email} onChange={(e) => {this.onEmailChange(e)}} />
                    </FloatingLabel>
                    <Form.Check className="mt-1" style={textStyle} label="Remember Email" checked={this.state.rememberMe} onClick={(e) => {this.onRememberEmailChange(e)}}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <FloatingLabel label="Password" style={{color:'black'}}>
                        <Form.Control className='mb-1' style={style} type="password" placeholder="Password" value={this.state.password} onChange={(e) => {this.onPasswordChange(e)}} />
                        <Link to={ROUTE.FORGOT_PASS}><a>Forgot your Password?</a></Link>
                    </FloatingLabel>
                </Form.Group>
                {this.state.errorMsg.trim() !== "" ? <Alert variant="danger"> There was an issue: {prettyFirebaseLog(this.state.errorMsg)} </Alert> : null}
                {this.state.success ? <Alert variant="success"> Logged in successfully! </Alert> : null}
                <Button className="" variant="primary" onClick={(e) => {this.loginUser(e)}}>
                    Login
                </Button>
                <Link to={ROUTE.SIGNUP}>
                    <Button style={{float:"right"}} variant="secondary">
                        Create an account
                    </Button>
                </Link>
            </Form>
        );
    }

}

const style = {
    backgroundColor: COLOR.PRIMARY_TAN, 
    borderColor: COLOR.SECONDARY_TAN
}

const textStyle = {
    color: 'white'
}