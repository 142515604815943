import IValidator from "../model/interfaces/IValidator";
import { REGEX } from "../utils/Constants";

//************************************************************************************************ 
//*  Loot Table
//************************************************************************************************/

export class LootTableQuantityValidator implements IValidator {

    validationMsg: string = "";

    validate(value: any){
        const maxChars = 24;
        if(isNaN(Number(value)) || Number(value) <= 0 || Number(value) > maxChars) {
            this.validationMsg = "Selection Quantity must be between 1 and " + maxChars;
            return false;
        }
        this.validationMsg = "";
        return true;
    }

    validateRange(min: number, max: number){
        if(Number(min) > Number(max)) {
            this.validationMsg = "Selection Minimum must be less than or equal to Selection Maximum";
            return false;
        }
        this.validationMsg = "";
        return true;
    }

}

//************************************************************************************************ 
//*  Loot
//************************************************************************************************/

export class LootWeightValidator implements IValidator {

    validationMsg: string = "";

    validate(value: string) {
        if ( value.length === 0 || !REGEX.WEIGHT.test(value.trim())) {
            this.validationMsg = "Weight must be less than or equal to 99999.99 lbs with up to 2 decimal places.";
            return false;
        }
        this.validationMsg = "";
        return true;
    }

}