
export enum Rarity {
    Junk = 70,
    Common = 100,
    Uncommon = 50,
    Rare = 10,
    Epic = 5,
    Legendary = 1
}

export enum RarityColor {
    Junk = '#999999',
    Common = '#33cc33',
    Uncommon = '#0099ff',
    Rare = '#cc33ff',
    Epic = '#ffcc00',
    Legendary = '#ff6600'
}

// Rarity Utility Functions

export function getRarityText(rarity: Rarity){
    return Rarity[rarity]
}

export function getRarityOptions(): string[]{
    return Object.keys(RarityColor);
}

export function getRarityValues(){
    return Object.values(Rarity);
}

export function getRarityColor(rarity: Rarity){
    const idx = Object.keys(RarityColor).indexOf(Rarity[rarity]);
    return Object.values(RarityColor)[idx];
}