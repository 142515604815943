import { REGEX } from '../../utils/Constants';

export default class DatastoreKey {

    private COLLECTION: string;
    private KEY: string;
    private INDEX_KEY: string;

    constructor(collection: string, key: string){
        this.COLLECTION = collection;
        this.KEY = key;
        this.INDEX_KEY = this.COLLECTION + ":Index";
    }

    getCollection(){
        return this.COLLECTION;
    }

    getCollectionKey(){
        return this.KEY;
    }

    getIndexKey(){
        return this.INDEX_KEY;
    }

    getKey(){
        return this.COLLECTION + ":" + this.KEY;
    }

    static parseKey(key: string): DatastoreKey | null {
        const collection = key.match(REGEX.COLLECTION);
        const uuid = key.match(REGEX.UUID);
        if(collection && collection[0] && uuid && uuid[0]) {
            return new DatastoreKey(collection[0],uuid[0]);
        }
        return null;
    }

    isSystemData() {
        return REGEX.SYSTEM_COLLECTION.test(this.COLLECTION);
    }
    
    isEqual(key: DatastoreKey) {
        return this.getKey() === key.getKey();
    }

}

export function getDatastoreKey(collection: string, key: string): DatastoreKey {
    return new DatastoreKey(collection, key);
}