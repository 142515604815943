import React from 'react';
import { Badge, Card } from 'react-bootstrap';
import { FaWeightHanging } from 'react-icons/fa';
import { GiTwoCoins } from 'react-icons/gi';
import CustomTooltip from '../../components/system/CustomTooltip';
import Loot from '../../model/loot/Loot';
import { Rarity } from '../../model/loot/Rarity';
import { trimDisplayText } from '../../utils/AppUtilities';
import { COLOR, SCREEN, TEXT } from '../../utils/Constants';
import ActionButton, { ActionMenuRenderOptions } from '../system/buttons/ActionButton';

interface IProps {
    className?: string;
    style?: any;
    sortKey?: string;
    data: Loot;
    menuOptions?: ActionMenuRenderOptions[];
    onClick?: (event: any, data: Loot) => void;
    /** Overide default menu item rendering */
    onRenderMenuItem?: (data: Loot, option: ActionMenuRenderOptions) => React.ReactNode;
}

interface IState {
    isMouseOver: boolean;
    isScreenXSmall: boolean;
}

/**
 * 
 */
export default class LootItemCard extends React.Component<IProps, IState> {

    constructor(props: IProps){
        super(props);
        this.state = {
            isMouseOver:false,
            isScreenXSmall: window.innerWidth < SCREEN.SMALL
        }
    }
    
    componentDidMount(){
        // resize handler
        window.addEventListener('resize', () => {
            this.setState({
                isScreenXSmall: window.innerWidth < SCREEN.SMALL
            });
        }, false);
    }

    getActionMenu(){
        if(!this.props.menuOptions || this.props.menuOptions.length <= 0) return undefined;
        const menuItems: React.ReactNode[] = [];
        const options: ActionMenuRenderOptions[] = this.props.menuOptions;

        for(var i=0; i<options.length; i++){
            const option = options[i];
            if(this.props.onRenderMenuItem){ 
                const el = this.props.onRenderMenuItem(this.props.data, option);
                if((el as React.ReactElement).type.length > 0) menuItems.push(el);
            }
            else menuItems.push(<ActionButton.Menu text={option.text} onClick={(e: any) => {option.onClick(e,this.props.data)}} />)
        }

        if(menuItems.length > 0){
            return (
                <ActionButton className={ this.props.data.SYSTEM_DATA ? "col-1 px-1" : "col-2 px-1" } style={{textAlign: 'right'}} align="end" >
                    {menuItems}
                </ActionButton>
            )
        }
        return undefined;
    }

    render() {
        
        const style = {
            boxShadow:"0px 0px" + ( this.state.isMouseOver ? " 15px " : " 8px ") + this.props.data.getRarityColor(),
            height:"100%",
            transform: this.state.isMouseOver ? "scale(1.03,1.03)" : "scale(1,1)",
            backgroundColor: COLOR.TERTIARY_BG_COLOR,
            color:'white'
        }
        
        const rarityStyle = {
            color:this.props.data.getRarityColor()
        }

        return (
            <div className={"pb-2 my-2 "+this.props.className} style={this.props.style} onClick={(e) => {this.props.onClick?.(e,this.props.data)}}>
                <Card style={style} onMouseEnter={(e) => {this.setState({isMouseOver:true})}} onMouseLeave={(e) => {this.setState({isMouseOver:false})}}>
                    <Card.Header as="h5" style={{backgroundColor:'#232c33'}}>
                        <div className="row align-items-center">
                            <div className="col-10">{this.props.data.name}</div>
                            {this.props.data.SYSTEM_DATA ? <div className={ this.getActionMenu() ? "col-1 px-1" : "col-2 px-1"} style={{ textAlign: 'right', verticalAlign:'middle' }}><CustomTooltip tooltip={TEXT.SYSTEM_LOOT_TOOLTIP}><Badge bg="brick" style={{maxWidth: '32px', maxHeight: '28px'}}>A</Badge></CustomTooltip></div> : null}
                            {this.getActionMenu()}
                            <h3 className="col-12 my-1" >{this.props.data.category}</h3>
                        </div>
                    </Card.Header>
                    <div className="mx-3 my-2">
                        <Card.Subtitle className="mb-2 p-0">
                            <div style={rarityStyle}>
                                {Rarity[this.props.data.rarity]}
                            </div>
                            <div className="row mt-2">
                                <div className="col-12 col-sm-5 mt-2" style={{color:COLOR.SECONDARY_BRICK}}>
                                    <FaWeightHanging className="icon" /> {this.props.data.weight} lbs
                                </div>
                                <div className="col-12 col-sm-7 currency mt-2" style={this.state.isScreenXSmall ? {textAlign:'left'} : {textAlign:'right'}}>
                                    <GiTwoCoins className="icon" /> {this.props.data.value}
                                </div>
                            </div>
                        </Card.Subtitle>
                        <Card.Text>{trimDisplayText(this.props.data.description,280)}</Card.Text>
                    </div>
                </Card>
            </div>
        );
    }

}

