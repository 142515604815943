import React, { CSSProperties } from 'react';
import { CloseButton } from 'react-bootstrap';

interface IProps {
    className?: string;
    style?: CSSProperties;
    title?: string;
    showCloseButton?: boolean;
}

interface IState {
    show: boolean;
}

export default class InformationBlock extends React.Component<IProps, IState> {

    public static FullText = FullText;
    public static OneThird = OneThird;

    constructor(props: IProps) {
        super(props);
        this.state = {
            show: true
        }
    }

    componentDidMount() {
    }

    onCloseButtonClick() {
        this.setState({ show: false });
    }

    render() {
        return (
            <div>
                {this.state.show ?
                    <div className={"page-container " + this.props.className} style={{ ...blockStyle, ...this.props.style }}>
                        {this.props.showCloseButton ? <div style={{ textAlign: "right", marginRight: "-32px" }}><CloseButton onClick={() => { this.onCloseButtonClick() }} aria-label="Hide" /></div> : null}
                        <h1 className="boldTitle" style={{ textAlign: 'center' }}>{this.props.title}</h1>
                        <div className="container-fluid">
                            <div className="row">
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                    : null}
            </div>
        );
    }

}

// Convience Components

interface IFullTextProps {
    className?: string;
    style?: CSSProperties;
    children?: React.ReactNode;
}

function FullText(props: IFullTextProps) {

    return (
        <div className={"col-12 " + props.className} >
            <h5 style={props.style}>{props.children}</h5>
        </div>
    );

}

interface IThirdTextProps {
    className?: string;
    style?: CSSProperties;
    children?: React.ReactNode;
    title?: string;
    hrStyle?: CSSProperties;
}

function OneThird(props: IThirdTextProps) {

    return (
        <div className={"col-md-4 mt-3 " + props.className}>
            {props.title ? <h4>{props.title}</h4> : null}
            <hr style={props.hrStyle} />
            <div style={props.style}>{props.children}</div>
        </div>
    );

}

const blockStyle: CSSProperties = {
    padding: "32px 64px 32px 64px",
    marginBottom: "16px",
    minHeight: "100px",
    color:'white'
}