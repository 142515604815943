import React from 'react';
import Compare from '../../utils/Compare';

interface IProps {
    by: string;
    reverse?: boolean;
    variation?: string;
}

export default class Sorter extends React.Component<IProps> {

    compare(a: React.ReactNode,b: React.ReactNode): number{
        return Compare(a,b,this.props.by);
    }

    render() {
        // return original children
        if(!this.props.by) return this.props.children;
        // sort
        const sorted = React.Children.toArray(this.props.children).sort((a,b) => this.compare(a,b));
        if(this.props.reverse) return sorted.reverse();
        return sorted;
    }

}

interface ISortableProps {
    sortKey: string;
}


export class Sortable extends React.Component<ISortableProps> {
    
    render(): React.ReactNode {
        return (
            <div key={this.props.sortKey}>
                {this.props.children}
            </div>
        );
    }

}