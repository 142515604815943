import React from 'react';
import { useParams } from 'react-router-dom';
import LootDetailsEdit from '../../components/loot/LootDetailsEdit';
import LoadingSpinner from '../../components/system/LoadingSpinner';
import NothingHere from '../../components/system/NothingHere';
import CloudDatastore from '../../controllers/datastore/CloudDatastore';
import { LOOT_COLLECTION_KEY, SYSTEM_LOOT_COLLECTION_KEY } from '../../controllers/datastore/Datastore';
import DatastoreKey from '../../model/datastore/DatastoreKey';
import Loot from '../../model/loot/Loot';
import { TITLE } from '../../utils/Constants';

interface IProps {
    id: string;
}

interface IState {
    loot: Loot | undefined;
    loading: boolean;
}

class LootEditPageClass extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            loot: undefined,
            loading:true
        }
        document.title = TITLE.LOOT_EDIT;
    }

    componentDidMount(){
        this.loadLoot();
    }

    async loadLoot(){
        this.setState({loading:true});

        const store = new CloudDatastore();
        const table = await store.get(new DatastoreKey(LOOT_COLLECTION_KEY, this.props.id), true);
        const l: Loot = Loot.toClass(table as Object);
        
        console.log(l);

        if(l){
            this.setState({ loot:l, loading: false });
        } else {
            // System Loot
            const table = await store.get(new DatastoreKey(SYSTEM_LOOT_COLLECTION_KEY, this.props.id), true);
            const t: Loot = Loot.toClass(table as Object);

            if(t){
                this.setState({ loot:t, loading: false });
            } else {
                this.setState({loading:false});
            }
        }
    }

    // Render

    render() {
        return (
            <div className="route-page">
                { this.state.loading ? <LoadingSpinner /> : null }
                { !this.state.loot ? <NothingHere />  : 
                    <LootDetailsEdit
                        loot={this.state.loot}
                        subtitle="Loot"
                    /> 
                }
            </div>
        );
    }

}

/**
 * Function wrapper to make use of hooks
 * @param {*} props 
 * @returns component
 */
 export default function LootEditPage(props: IProps){
    let params = useParams();
    return <LootEditPageClass id={params.id ? params.id : ""}/>
}