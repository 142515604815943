import React from 'react';
import { Accordion, Badge } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import LootTableDetailsEdit from '../../components/loot/LootTableDetailsEdit';
import RarityView from '../../components/loot/RarityView';
import { AddButton } from '../../components/system/buttons/CommonButtons';
import ContentBlock from '../../components/system/ContentBlock';
import CustomTooltip from '../../components/system/CustomTooltip';
import LoadingSpinner from '../../components/system/LoadingSpinner';
import ConfirmationPrompt from '../../components/system/prompts/ConfrimationPrompt';
import { getDatastore } from '../../controllers/datastore/Datastore';
import { setUser } from '../../controllers/redux/reducers/UserReducer';
import { RootState } from '../../controllers/redux/Store';
import LootTable from '../../model/loot/LootTable';
import { Storable } from '../../model/Storable';
import { trimDisplayText } from '../../utils/AppUtilities';
import { EVENT, ROUTE, SCREEN, TEXT, TITLE } from '../../utils/Constants';
import { logAnalyticsEvent } from '../../utils/Logger';
import { getAllLootTables } from '../../utils/LootHelper';

//************************************************************************************************ 
//*  State & Props
//************************************************************************************************/
interface IProps extends PropsFromRedux{}

interface IState {
    loading: boolean;
    tables: LootTable[];
    confirmDeleteTable: boolean;
    confirmDeleteLoot: boolean;
    deleteTable: LootTable | undefined;
    isMobile: boolean;
}

//************************************************************************************************ 
//*  Redux
//************************************************************************************************/
const mapState = (state: RootState) => ({
    user: state.userState.user
});

const mapDispatch = {
    setUser: setUser
}

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

//************************************************************************************************ 
//*  Component
//************************************************************************************************/
class LootTablePage extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: true,
            tables: [],
            confirmDeleteTable: false,
            confirmDeleteLoot: false,
            deleteTable: undefined,
            isMobile: window.innerWidth < SCREEN.XLARGE
        }
        document.title = TITLE.LOOTTABLE;
    }

    componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({
                isMobile: window.innerWidth < SCREEN.XLARGE
            });
        }, false);
        this.loadData().then(() => {
            this.setState({loading:false});
        });
    }

    async loadData() {
        const tables: LootTable[] = await getAllLootTables(this.props.user);
        for(const table of tables){
            await Storable.resolveReferences(table); // todo lazy load
        }
        this.setState({ tables: tables });
    }

    //************************************************************************************************ 
    //*  Event Handlers
    //************************************************************************************************/

    onEdit(event: any, table: LootTable) {
        window.location.href = ROUTE.LOOT_TABLE_EDIT + table.UUID;
    }

    onNewLootTable(event: any) {
        event.preventDefault();
        // New Loot Table
        const table = new LootTable("New Loot Table");
        table.store().then(() => {
            logAnalyticsEvent(EVENT.NEW_LOOT_TABLE_INSTANCE);
            window.location.href = ROUTE.LOOT_TABLE_EDIT + table.UUID;
        });
    }

    // Delete

    onDeleteTable(event: any, table: LootTable) {
        this.setState({ confirmDeleteTable: true, deleteTable: table });
    }

    onDeleteTableConfirmed(event: any) {
        const store = getDatastore();
        if (this.state.deleteTable) {
            store.remove(this.state.deleteTable).then(() => {
                logAnalyticsEvent(EVENT.DELETE_LOOT_TABLE_INSTANCE);
                this.setState({ confirmDeleteTable: false, deleteTable: undefined });
                this.loadData();
            });
        }
    }

    onDeleteDeny(event: any) {
        this.setState({ confirmDeleteTable: false, confirmDeleteLoot: false });
    }

    //************************************************************************************************ 
    //*  Render
    //************************************************************************************************/

    renderTables() {
        const component = [];
        const tables = this.state.tables;
        // Get Loot Tables
        for (let i = 0; i < tables.length; i++) {
            const table = this.state.tables[i];
            const rarity = table.getRarity();
            // Create new element
            const el = (
                <Accordion.Item key={i} eventKey={i.toString()}>
                    <Accordion.Header>
                        {this.state.isMobile ? null : <h4 style={{ marginBottom: '0px', marginRight: '16px' }}><RarityView style={{ margin: "0px 8px" }} rarity={rarity} /></h4>}
                        {table.SYSTEM_DATA ? <CustomTooltip tooltip={TEXT.SYSTEM_LOOT_TABLE_TOOLTIP} ><Badge style={{ marginRight: "8px" }} bg="brick">A</Badge></CustomTooltip> : null}
                        <h4 style={{ marginBottom: '0px' }}>{this.state.isMobile ? trimDisplayText(table.name, 25) : table.name}</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                        <LootTableDetailsEdit table={table} onEdit={this.onEdit} onDelete={(e, t) => { this.onDeleteTable(e, t) }} />
                    </Accordion.Body>
                </Accordion.Item>
            )
            component.push(el)
        }
        return component;
    }

    render() {

        return (
            <div className="route-page">
                {this.state.confirmDeleteTable ? <ConfirmationPrompt onClickYes={(e) => { this.onDeleteTableConfirmed(e) }} onClickNo={(e) => { this.onDeleteDeny(e) }} title="Are you sure you would like to delete this Loot Table?" /> : null}
                { this.state.loading ? <LoadingSpinner /> : 
                    <ContentBlock>
                        <ContentBlock.Title title="Loot Tables">
                            <div className="row" style={{ margin: '8px 0px' }}>
                                <div className="col-md-10" />
                                <AddButton className="col-md-2" tooltip="Add New Loot Table" onClick={(e) => { this.onNewLootTable(e) }} disabled={this.props.user === null} lock={this.props.user === null}>Create Loot Table</AddButton>
                            </div>
                        </ContentBlock.Title>
                        <ContentBlock.Content>
                            {this.state.tables.length === 0 ? <h2 className="text-subdued" style={{ textAlign: 'center', margin: '15px' }}>All your base are belong to us</h2> : null}
                            <Accordion>
                                {this.renderTables()}
                            </Accordion>
                        </ContentBlock.Content>
                    </ContentBlock>
                }
            </div>
        );
    }

}

export default connector(LootTablePage);