import { instanceToPlain, plainToInstance, Type } from 'class-transformer';
import { getAuth } from 'firebase/auth';
import CloudDatastore from '../controllers/datastore/CloudDatastore';
import { getDatastore } from '../controllers/datastore/Datastore';
import { logWarning } from '../utils/Logger';
import DatastoreKey from './datastore/DatastoreKey';
import INameable from './interfaces/INameable';
import MetricMetadata from './MetricMetadata';


//************************************************************************************************ 
//*  Storable
//************************************************************************************************/

/**
 * Makes an Object Storable in the Datastore
 */
export abstract class Storable extends MetricMetadata implements INameable {

    name: string = "";
    
    /** Unique Storage Key */
    @Type(() => DatastoreKey)
    STOR_KEY: DatastoreKey;
    /** is Prepopulated System Data  */
    SYSTEM_DATA = false;
    /** User ID for Object Owner */
    OWNER_ID: string | undefined;

    /** List of Referenced Objects  */
    @Type(() => StorableReference)  
    REFERENCES: StorableReference[] = [];

    //************************************************************************************************ 
    //*  Init
    //************************************************************************************************/

    constructor(name: string, collection: string){
        super();
        this.name = name;
        this.STOR_KEY = new DatastoreKey(collection, this.UUID);
        this.OWNER_ID = getAuth().currentUser?.uid;
    }

    //************************************************************************************************ 
    //*  Storage
    //************************************************************************************************/

    /**
     * Store storable in datastore
     * @param callback 
     */
    async store(): Promise<void> {
        return getDatastore().set(this);
    }

    /**
     * Remove storable from datastore
     * @param callback 
     */
    async remove(): Promise<void> {
        return getDatastore().remove(this);
    }

    //************************************************************************************************ 
    //*  Transformations
    //************************************************************************************************/

    toObject(): Object {
        return instanceToPlain(this);
    }

    static toClass(obj: Object): any {
        throw Error("Method not implemented");
    }

    //************************************************************************************************ 
    //*  References
    //************************************************************************************************/

    addReference(storable: Storable): void {
        this.REFERENCES.push(new StorableReference(storable));
    }

    getReferences(): StorableReference[] {
        const refs: StorableReference[] = this.REFERENCES;
        var found: StorableReference[] = [];
        for(var e of refs){
            found.push(e);
        }
        return found;
    }

    removeReferencable(storable: StorableReference): void {
        const idx = this.REFERENCES.findIndex((e) => {
            return e.isEqual(storable);
        });
        this.REFERENCES.splice(idx, 1);
    }

    static createReferences(storable: Storable){
        storable.REFERENCES.forEach((e) => {
            e.link();
        });
    }

    static async resolveReferences(storable: Storable, includeSubReferences?: boolean){
        var references: StorableReference[] = [];
        const resolvedReferences = [];
        if(storable){
            for(var storableReference of storable.REFERENCES){
                const ref = plainToInstance(StorableReference, storableReference);
                resolvedReferences.push(ref.resolve(includeSubReferences));
            }
            const refs = await Promise.all(resolvedReferences);
            if (refs) {
                storable.REFERENCES = refs as StorableReference[];
                references = storable.REFERENCES;
            }
        } else {
            logWarning("Storable - resolveReferences", "Storable is undefined");
        }
        return references;
    }

}

//************************************************************************************************ 
//*  StorableReference
//************************************************************************************************/

/**
 * Wrapper class to store referencable objects
 */
export class StorableReference {

    STORABLE_TYPE: string;

    @Type(() => DatastoreKey)
    referenceKey: DatastoreKey;
    
    @Type(() => Storable)
    storable: Storable | null;

    constructor(storable: Storable | DatastoreKey){
        if(storable instanceof Storable){
            this.STORABLE_TYPE = storable.constructor.name;
            this.referenceKey = storable.STOR_KEY;
            this.storable = storable;
        } else {
            this.STORABLE_TYPE = "";
            this.referenceKey = storable;
            this.storable = null;
        }
    }

    /**
     * Create reference link to Storable instance
     */
    link(): void {
        if(this.storable){
            this.referenceKey = this.storable.STOR_KEY;
        }
        this.storable = null;
    }

    /**
     * Resolve Storable reference to instance
     * @param includeSubReferences
     */
    async resolve(includeSubReferences?: boolean): Promise<StorableReference | undefined> {
        if (this.referenceKey){
            let storable;
            if(this.referenceKey.isSystemData() == true) {
                const ds = new CloudDatastore();
                storable = await ds.get(this.referenceKey, includeSubReferences);
            } else {
                storable = await getDatastore().get(this.referenceKey, includeSubReferences);
            }
            if(storable) this.storable = storable;
        }
        return this;
    }

    isEqual(ref: StorableReference){
        if(this.referenceKey === ref.referenceKey) return true;
        return false;
    }

}

type Callback = (data?: any) => void