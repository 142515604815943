import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import React from 'react';
import EditableField from '../../components/system/EditableField';
import PersistanceHeader from '../../components/system/PersistanceHeader';
import { SYSTEM_LOOT_TABLE_COLLECTION_KEY } from '../../controllers/datastore/Datastore';
import IValidator from '../../model/interfaces/IValidator';
import Loot from '../../model/loot/Loot';
import LootTable from '../../model/loot/LootTable';
import { isDevEnv } from '../../utils/AppUtilities';
import { ComponentConfigurations, ComponentState } from '../../utils/CommonTypes';
import { ROUTE } from '../../utils/Constants';
import { saveAsSystemData } from '../../utils/LootHelper';
import ActionButton, { ActionMenuRenderOptions } from '../system/buttons/ActionButton';
import { ShareButton } from '../system/buttons/CommonButtons';
import LootList from './LootList';

interface IProps {
    table: LootTable;
    quantityValidator?: IValidator;
    state?: ComponentConfigurations;
    
    isDirty?: boolean;
    invalidQuantity?: boolean;
    onEdit?: (event: any, table: LootTable) => void;
    onEditLoot?: (event: any, data: Loot) => void;
    onDelete?: (event: any, table: LootTable) => void;
    onDeleteLoot?: (event: any, data: Loot) => void;
    onAddLoot?: (event: any, table: LootTable) => void;
    onAddNewLoot?: (event: any, table: LootTable) => void;
    onMinQuantityEdit?: (value: any, valid: boolean) => void;
    onMaxQuantityEdit?: (value: any, valid: boolean) => void;
    onVisibilityEdit?: (value: any, valid: boolean) => void;
    onRevert?: (event: any) => void;
    onSubmit?: (event: any) => void;
    onSelectLoot?: () => void;
}

interface IState {
    minQuantity: number;
    maxQuantity: number;
    user: User | undefined;
}

/**
 * Loot Table Detail Component
 */
export default class LootTableDetailsEdit extends React.Component<IProps, IState> {

    EDIT_LOOT_TEXT = "Edit Loot";
    REMOVE_LOOT_TEXT = "Remove Loot";

    constructor(props: IProps) {
        super(props);

        const table = this.props.table;
        this.state = {
            minQuantity: table.minQuantity,
            maxQuantity: table.maxQuantity,
            user: undefined
        }
    }

    componentDidMount() {
        // auth
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // logged in
                this.setState({user:user});
            } else {
                // logged out
                this.setState({user:undefined});
            }
        });
    }

    isState(state: ComponentState){
        return this.props.state && this.props.state.includes(state);
    }

    //************************************************************************************************ 
    //*  Render
    //************************************************************************************************/

    getMenuOptions() {
        const options: ActionMenuRenderOptions[] = [];
        if (this.props.onEditLoot) options.push({ text: this.EDIT_LOOT_TEXT, onClick: this.props.onEditLoot });
        if (this.props.onDeleteLoot) options.push({ text: this.REMOVE_LOOT_TEXT, onClick: this.props.onDeleteLoot });
        return options;
    }

    renderMenuItem(data: Loot, option: ActionMenuRenderOptions) {
        if (data.SYSTEM_DATA) {
            if (option.text === this.REMOVE_LOOT_TEXT) {
                return <ActionButton.Menu text={option.text} onClick={(e: any) => { option.onClick(e, data) }} />;
            }
            return (<></>);
        } else {
            return <ActionButton.Menu text={option.text} onClick={(e: any) => { option.onClick(e, data) }} />;
        }
    }

    render() {
        const table = this.props.table;
        const loot = table.getItems();

        return (
            <div>
                {/* Button Bar */}
                <div style={{ width: '100%', textAlign: 'right' }}>
                    {/* Share Button */}
                    {table.privacy === "Private" || !this.state.user ? null : <ShareButton style={buttonStyle} link={window.location.host + ROUTE.LOOT_TABLE + table.UUID} />}
                    {/* Action Button */}
                    {table.SYSTEM_DATA && !isDevEnv() ? null :
                        <ActionButton style={buttonStyle} >
                            {/* Menu Items */}
                            {!this.isState("Edit") ?
                                <>
                                    <ActionButton.Menu text="Edit Loot Table" onClick={(e: any) => { this.props.onEdit?.(e, table) }} />
                                    <ActionButton.Menu text="Delete Loot Table" onClick={(e: any) => { this.props.onDelete?.(e, table) }} />
                                </>
                            : null}
                            {this.isState("Edit") ?
                                <>
                                    <ActionButton.Menu text="Add Existing Loot" onClick={(e) => { this.props.onAddLoot?.(e, table) }} />
                                    <ActionButton.Menu text="Create New Loot" onClick={(e) => { this.props.onAddNewLoot?.(e, table) }} />
                                </>
                            : null}
                            {/* Admin Menu Items */}
                            { isDevEnv() ? 
                                <>
                                    <ActionButton.Menu text="(A) Save as System Data" onClick={(e) => { saveAsSystemData(table, SYSTEM_LOOT_TABLE_COLLECTION_KEY)}} />
                                </>
                            : null }
                        </ActionButton>
                    }
                </div>
                {/* Loot Table Fields */}
                <div className="row">
                    {this.isState("Edit") ?
                        <div>
                            <div className="a-dropdown mb-4" style={{}}><EditableField dropdown dropdownValues={['Public', 'Private']} text={table.privacy} onEdit={this.props.onVisibilityEdit}>Privacy:</EditableField></div>
                            <div className="field"><EditableField text={table.minQuantity.toString()} invalid={this.props.invalidQuantity} onEdit={this.props.onMinQuantityEdit} validator={this.props.quantityValidator}>Selection Minimum:</EditableField></div>
                            <div className="field"><EditableField text={table.maxQuantity.toString()} invalid={this.props.invalidQuantity} onEdit={this.props.onMaxQuantityEdit} validator={this.props.quantityValidator}>Selection Maximum:</EditableField></div>
                        </div>
                        : <h5 className="col-md-10">Picks {table.minQuantity} to {table.maxQuantity} items</h5>}
                </div>
                {this.isState("Edit") ? <PersistanceHeader isDirty={this.props.isDirty} onRevert={this.props.onRevert} onSubmit={this.props.onSubmit} /> : null}
                <hr />
                {/* Loot List */}
                <LootList
                    data={loot}
                    menuOptions={this.getMenuOptions()}
                    onClick={this.props.onSelectLoot}
                    onRenderMenuItem={(d, o) => { return this.renderMenuItem(d, o) }}
                />
            </div>
        );
    }

}

const buttonStyle = {
    display: 'inline-block',
    margin: '0',
    width: '32px',
    height: '32px'
}