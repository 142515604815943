import React, { CSSProperties } from 'react';
import { Alert, Dropdown, Form } from 'react-bootstrap';
import { FaExclamationTriangle, FaLock } from 'react-icons/fa';
import IValidator from '../../model/interfaces/IValidator';
import { COLOR } from '../../utils/Constants';

interface IProps {
    className?: string;
    style?: CSSProperties;
    text?: string;
    textarea?: boolean;
    readOnly?: boolean;
    plaintext?: boolean;
    resize?: boolean;
    dropdown?: boolean;
    dropdownValues?: string[];
    dropdownColor?: string;
    validator?: IValidator;
    invalid?: boolean;
    onEdit?: (value: string, valid: boolean) => void;
}

interface IState {
    text: string;
    valid: boolean;
}

export default class EditableField extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            text: this.props.text ? this.props.text : "",
            valid: true
        }
    }

    // Event Handlers

    onChangeText(event: any) {
        const value = event.target.value;
        const state = { text: value, valid: true };
        if (this.props.validator) {
            state.valid = this.props.validator.validate(value);
        }
        this.props.onEdit?.(value, state.valid);
        this.setState(state);
    }

    onChangeDropdown(selection: string) {
        if (this.props.onEdit) this.props.onEdit(selection, true);
        this.setState({ text: selection })
    }

    // Rendering

    buildDropdown() {
        const values = this.props.dropdownValues;
        const elements = [];
        if (values && values.length > 0) {
            for (const item of values) {
                const el = <Dropdown.Item onClick={() => { this.onChangeDropdown(item) }}>{item}</Dropdown.Item>;
                elements.push(el);
            }
        } else {
            elements.push(<Dropdown.Item>No Tables</Dropdown.Item>);
        }

        return elements;
    }

    getDropdown() {
        return (
            <div>
                <Dropdown>
                    <Dropdown.Toggle style={{ width: '100%', backgroundColor: this.props.dropdownColor }} variant={this.props.dropdownColor ? undefined : 'primary'} disabled={this.props.readOnly}>
                        {this.state.text ? this.state.text : "Select"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="dark">
                        {this.buildDropdown()}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }

    getForm() {
        const resize = this.props.resize ? 'vertical' : 'none';
        const bgColor = this.props.readOnly ? "gray" : COLOR.PRIMARY_TAN ;

        var style = this.props.style;
        if (this.props.textarea) style = {...style, ...{ height: '100px', resize: resize, backgroundColor: bgColor, borderColor: COLOR.SECONDARY_TAN }};
        else if (!this.props.plaintext) style = { ...style, ...{ backgroundColor: bgColor, borderColor: COLOR.SECONDARY_TAN }};

        return (
            <div>
                <Form style={{ position: 'relative' }}>
                    <Form.Control
                        as={this.props.textarea ? "textarea" : undefined}
                        style={style}
                        readOnly={this.props.readOnly}
                        plaintext={this.props.plaintext}
                        defaultValue={this.state.text}
                        onChange={(e) => { this.onChangeText(e) }}
                    />
                    {this.props.readOnly ? <FaLock style={iconStyle} /> : null}
                </Form>
            </div>
        );
    }

    getFieldType() {
        if (this.props.dropdown) {
            return this.getDropdown();
        } else {
            return this.getForm();
        }
    }

    render() {
        return (
            <div className={this.props.className} style={this.props.style}>
                <h5>{this.props.children}</h5>
                <div>
                    {this.getFieldType()}
                </div>
                {this.state.valid && !this.props.invalid ? null : <Alert style={alertStyle} variant="danger"><FaExclamationTriangle className="icon" /> {this.props.validator ? this.props.validator.validationMsg : "There was an error"}</Alert>}
            </div>
        );
    }

}

const alertStyle: CSSProperties = {
    marginTop: '8px',
    fontSize: '1rem',
    fontWeight: 'normal'
}

const iconStyle: CSSProperties = {
    position: 'absolute',
    right: '8px',
    top: '50%',
    transform: 'translate(0%, -50%)',
    color: 'black'
}