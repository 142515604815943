import React from 'react';
import ContentBlock from '../components/system/ContentBlock';
import { TITLE, URL } from '../utils/Constants';

interface IProps {

}

interface IState {

}

export default class FoundersPage extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        document.title = TITLE.ABOUT;
    }

    componentDidMount(): void {
        document.querySelector('.app')?.scrollTo({top:0,behavior:'smooth'});
    }

    render() {
        return (
            <div className="route-page container-fluid">
                <div className="row">
                    <div className="col">
                        <ContentBlock>
                            <ContentBlock.Title title="Founders" />
                            <ContentBlock.Content grow style={blockStyle}>
                                I offer my most humble and sincere gratitude to the founders of Alderman RPG without whom I would not be able to create this app
                                <ul>
                                    <li>Jake Neuwelt</li>
                                </ul>
                            </ContentBlock.Content>
                            <p>Want to be a Founder? Find out how <a href={URL.DONATE} target="_blank">here</a>.</p>
                        </ContentBlock>
                    </div>
                </div>
            </div>
        );
    }

}

const blockStyle = {
    padding:"32px"
}