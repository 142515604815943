import React from 'react';
import ContentBlock from '../../components/system/ContentBlock';
import Sorter, { Sortable } from '../../components/system/Sorter';
import LootContainer from '../../model/loot/LootContainer';
import APagination from '../system/APagination';
import LootList from './LootList';

interface IProps {
    className?: string;
    style?: any;
    data: LootContainer[];
}

interface IState {
    page: number;
}

export default class LootHistoryView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            page: 1
        }
    }

    renderLootLists(){
        const history = this.props.data;
        const results = [];

        if(history){
            for (let container of history) {
                const key = container.getTimestamp().toLocaleString();
                results.push(
                <Sortable sortKey={key} key={key} >
                    <div className="px-2 pt-2">
                        <h5> {container.loot.length} Loot from {container.lootTableName}</h5>
                        <h3>{container.getTimestamp().toLocaleString()}</h3>
                    </div>
                    <hr />
                    <LootList data={container.loot} />
                </Sortable>);
            }
        } else {
            results.push(<h3 style={{textAlign:'center'}}>Nothing here</h3>);
        }

        return results;

    }

    render() {
        const itemsPerPage = 8;
        const idx = ((itemsPerPage)*this.state.page) - (itemsPerPage);

        return (
            <div>
                <ContentBlock>
                    <ContentBlock.Title title="Loot History" />
                    <ContentBlock.Content>
                        <Sorter by="sortKey" reverse={true}>
                            {this.renderLootLists().slice(idx, idx+itemsPerPage)}
                        </Sorter>
                        <div>
                            <APagination numPages={this.props.data.length/itemsPerPage} onClickPage={(e, page) => {this.setState({page})}} />
                        </div>
                    </ContentBlock.Content>
                </ContentBlock>
            </div>
        );
    }

}