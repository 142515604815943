import { plainToInstance } from 'class-transformer';
import { getAuth, User } from 'firebase/auth';
import { collection, getDocs, getFirestore, where } from 'firebase/firestore';
import CloudDatastore from '../controllers/datastore/CloudDatastore';
import { getDatastore, LOOT_COLLECTION_KEY, LOOT_CONTAINER_COLLECTION_KEY, LOOT_TABLE_COLLECTION_KEY, SYSTEM_CATEGORY_KEY, SYSTEM_LOOT_COLLECTION_KEY, SYSTEM_LOOT_TABLE_COLLECTION_KEY } from '../controllers/datastore/Datastore';
import DatastoreKey, { getDatastoreKey } from '../model/datastore/DatastoreKey';
import Loot from '../model/loot/Loot';
import LootContainer from '../model/loot/LootContainer';
import LootTable from '../model/loot/LootTable';
import { Storable } from '../model/Storable';

//************************************************************************************************ 
//*  Loot Table
//************************************************************************************************/

/**
 * Returns all Loot Tables from Datastore
 * @param callback (t: LootTable[]) callback with results
 */
export async function getAllLootTables(user: User | null): Promise<LootTable[]> {
    const store = getDatastore();
    var results: LootTable[] = [];

    if(user) {
        const userId = user.uid;
    
        // Get user Loot Tables
        const tables = await store.getAll(getDatastoreKey(LOOT_TABLE_COLLECTION_KEY,""), where("OWNER_ID", "==", userId));
        if(tables){
            tables.forEach((table) => {
                results.push(LootTable.toClass(table));
            });
        }
    }

    const sys = await getSystemLootTables();
    results = results.concat(sys);
    
    return results;
}

/**
 * Returns Loot Table from Datastore
 * @param key 
 * @param callback 
 */
export async function getLootTable(key: DatastoreKey): Promise<LootTable | undefined> {
    const store = getDatastore();
    var table = await store.get(key, true);
    return LootTable.toClass(table as Object);
}

export async function getSystemLootTables(): Promise<LootTable[]> {
    const db = getFirestore();
    const c = collection(db, SYSTEM_LOOT_TABLE_COLLECTION_KEY);
    const response = await getDocs(c);
    const results: LootTable[] = [];
    // Cast each item as a Loot Table
    response.forEach((doc) => {
        results.push(plainToInstance(LootTable, doc.data()));
    });
    
    return results;
}

//************************************************************************************************ 
//*  Loot
//************************************************************************************************/

/**
 * Returns all Loot from Datastore
 * @param callback (t: Loot[]) callback with results
 */
export async function getAllLoot(): Promise<Loot[]> {
    const store = getDatastore();
    const userId = getAuth().currentUser?.uid;
    var results: Loot[] = [];

    // Get user Loot
    const tables = await store.getAll(new DatastoreKey(LOOT_COLLECTION_KEY,""), where("OWNER_ID", "==", userId));
    if(tables){
        tables.forEach((table) => {
            results.push(Loot.toClass(table));
        });
    }

    const sys = await getSystemLoot();
    results = results.concat(sys);
    
    return results;
}

/**
 * Returns Loot from Datastore
 * @param key 
 * @param callback 
 */
export async function getLoot(key: DatastoreKey) {
    const store = getDatastore();
    const storable = await store.get(key, true);
    return plainToInstance(Loot, storable);
}

export async function getSystemLoot(): Promise<Loot[]> {
    const db = getFirestore();
    const c = collection(db, SYSTEM_LOOT_COLLECTION_KEY);
    const response = await getDocs(c);
    const results: Loot[] = [];
    // Cast each item as a Loot Table
    response.forEach((doc) => {
        results.push(plainToInstance(Loot, doc.data()));
    });
    
    return results;
}

//************************************************************************************************ 
//*  Loot Containers
//************************************************************************************************/

/**
 * Returns all Loot Containers from Datastore
 * @param callback (t: LootContainer[]) callback with results
 */
export async function getAllLootContainers(user: User): Promise<LootContainer[]> {
    const store = getDatastore();
    const userId = user.uid;
    var results: LootContainer[] = [];

    // Get user Loot Tables
    const tables = await store.getAll(new DatastoreKey(LOOT_CONTAINER_COLLECTION_KEY,""), where("OWNER_ID", "==", userId));
    if(tables){
        tables.forEach((table) => {
            results.push(LootContainer.toClass(table));
        });
    }
    
    return results;
}

//************************************************************************************************ 
//*  Helper Funcitons
//************************************************************************************************/

function createStorKey(storable: Storable, postfix?: string) {
    var key = 'SYSTEM_' + storable.name.toUpperCase().replaceAll(' ', '_').replaceAll('/','_');
    if (postfix) key += '_' + postfix;
    if (storable instanceof Loot) {
        return new DatastoreKey(SYSTEM_LOOT_COLLECTION_KEY, key);
    } else if (storable instanceof LootTable) {
        createReferenceKeys(storable);
        return new DatastoreKey(SYSTEM_LOOT_TABLE_COLLECTION_KEY, key);
    } else {
        return new DatastoreKey(SYSTEM_CATEGORY_KEY, key);
    }
}

function createReferenceKeys(table: LootTable){
    for(const ref of table.getReferences()){
        // @ts-expect-error
        ref.referenceKey = new DatastoreKey(SYSTEM_LOOT_COLLECTION_KEY, ref.referenceKey);
    }
}

async function storeLoot(lootPool: Loot[]) {
    // Datastore
    const store = getDatastore();
    var loot = lootPool.pop();
    if (loot) {
        loot = Loot.toClass(loot);
        loot.OWNER_ID = "System";
        loot.SYSTEM_DATA = true;
        loot.STOR_KEY = createStorKey(loot);
        await store.set(loot);
        await storeLoot(lootPool);
    }
}

async function storeLootTables(lootPool: LootTable[]) {
    // Datastore
    const store = getDatastore();
    var table = lootPool.pop();
    if (table) {
        table = LootTable.toClass(table);
        table.OWNER_ID = "System";
        table.SYSTEM_DATA = true;
        table.STOR_KEY = createStorKey(table);
        await LootTable.resolveReferences(table, false);
        table.calculateTotalWeight();
        await store.set(table);
        await storeLootTables(lootPool);
    }
}

//************************************************************************************************ 
//*  Data Loading Funcitons - DO NOT USE - loading only
//************************************************************************************************/

/**
 * DO NOT USE IN PROD ENVIRONMENT
 * INTERNAL ADMINS ONLY
 * @param storable 
 * @param key 
 */
export async function saveAsSystemData(storable: Storable, collection: string) {
    const store = new CloudDatastore();
    const systemKey = new DatastoreKey(collection, storable.UUID);
    storable.STOR_KEY = systemKey;
    storable.SYSTEM_DATA = true;
    storable.OWNER_ID = "";
    store.set(storable);
}
