import { getAuth, onAuthStateChanged } from 'firebase/auth';
import React from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LootList from '../../components/loot/LootList';
import LootTableView from '../../components/loot/LootTableView';
import ContentBlock from '../../components/system/ContentBlock';
import LoadingSpinner from '../../components/system/LoadingSpinner';
import ConfirmationPrompt from '../../components/system/prompts/ConfrimationPrompt';
import PromptOverlay from '../../components/system/prompts/PromptOverlay';
import { LOOT_TABLE_COLLECTION_KEY } from '../../controllers/datastore/Datastore';
import { LootTableQuantityValidator } from '../../controllers/LootValidators';
import DatastoreKey from '../../model/datastore/DatastoreKey';
import { PrivacyMode } from '../../model/interfaces/IPublicAccess';
import Loot from '../../model/loot/Loot';
import LootTable from '../../model/loot/LootTable';
import { Rarity } from '../../model/loot/Rarity';
import { EVENT, ROUTE, TITLE } from '../../utils/Constants';
import { logAnalyticsEvent } from '../../utils/Logger';
import { getAllLoot, getLootTable } from '../../utils/LootHelper';

interface IProps {
    id: string;
}

interface IState {
    table: LootTable;
    selectionLoot: Loot[];
    loading: boolean;
    deleteLoot: Loot | undefined;
    confirmDeleteTable: boolean;
    confirmDeleteLoot: boolean;
    invalidQuantity: boolean;
    showLootSelector: boolean;
}

class LootTableEditClass extends React.Component<IProps, IState> {

    lootTableQuanitityValidator = new LootTableQuantityValidator();

    constructor(props: IProps) {
        super(props);
        this.state = {
            table: new LootTable(""),
            confirmDeleteTable:false,
            confirmDeleteLoot:false,
            loading:true,
            deleteLoot: undefined,
            invalidQuantity:false,
            showLootSelector:false,
            selectionLoot:[]
        }
        document.title = TITLE.LOOTTABLE_EDIT;
    }

    componentDidMount(){
        // auth
        onAuthStateChanged(getAuth(), (user) => {
            this.loadData().then(() => {
                this.setState({loading:false});
            });
        });
    }

    async loadData(){
        this.setState({loading:true});
        const t: LootTable | undefined = await getLootTable(new DatastoreKey(LOOT_TABLE_COLLECTION_KEY, this.props.id));
        if(t){
            this.setState({ table:t, loading: false, invalidQuantity:false });
        }
    }

    storeTable() {
        const table = this.state.table;
        table.store().then(() => {
            toast("Loot Table Saved");
            this.loadData();
        });
    }

    //************************************************************************************************ 
    //*  Event Handlers - Loot Table Fields
    //************************************************************************************************/

    onEditName(text: string, valid: boolean){
        if(valid){
            const table = this.state.table;
            table.name = text;
            this.setState({table:table});
        }
    }

    onMinQuantityEdit(value: number, valid: boolean){
        const table = this.state.table;
        if(valid && this.lootTableQuanitityValidator.validateRange(value,table.maxQuantity)){
            table.minQuantity = value;
            this.setState({ table: table, invalidQuantity: false });
        } else {
            this.setState({invalidQuantity:true});
        } 
    }

    onMaxQuantityEdit(value: number, valid: boolean){
        const table = this.state.table;
        if(valid && this.lootTableQuanitityValidator.validateRange(table.minQuantity,value)){
            table.maxQuantity = value;
            this.setState({ table: table, invalidQuantity: false });
        } else {
            this.setState({invalidQuantity:true});
        }           
    }

    onVisibilityEdit(value: PrivacyMode, valid: boolean){
        const table = this.state.table;
        table.privacy = value;
        this.setState({ table: table, invalidQuantity: false });
    }

    //************************************************************************************************ 
    //*  Events Handlers - Loot
    //************************************************************************************************/

    onEditLoot(event: any, data: Loot){
        window.location.href=ROUTE.LOOT_EDIT+data.UUID;
    }

    onDeleteLoot(event: any, data: Loot){
        this.setState({confirmDeleteLoot:true,deleteLoot:data});
    }

    onDeleteLootConfrimation(){
        const table = this.state.table;
        if(this.state.deleteLoot) table.removeLoot(this.state.deleteLoot);
        this.setState({confirmDeleteLoot:false, deleteLoot: undefined});
        table.store().then(() => {
            this.loadData();
        });
    }

    onDeleteDeny(){
        this.setState({confirmDeleteLoot:false, deleteLoot: undefined});
    }

    onAddNewLoot(){
        const table = this.state.table;
        const loot = new Loot("New Loot",Rarity.Common);
        loot.store().then(() => {
            table.addLoot(loot);
            table.store().then(() => {
                logAnalyticsEvent(EVENT.NEW_LOOT_INSTANCE);
                this.setState({loading:true});
                this.loadData();
            });
        });
    }

    onAddLoot(){
        getAllLoot().then((loot: Loot[]) => {
            this.setState({showLootSelector:true, selectionLoot:loot});
        });
    }

    onLootSelected(event: any, loot: Loot){
        const table = this.state.table;
        table.addLoot(loot);
        table.store().then(() => {
            this.setState({showLootSelector:false});
            this.loadData();
        });
    }

    onCloseLoot() {
        this.setState({ showLootSelector: false });
    }

    //************************************************************************************************ 
    //*  Render
    //************************************************************************************************/

    render() {
        const table = this.state.table;
        return (
            <div className="route-page">
                { this.state.showLootSelector ? 
                    <PromptOverlay className="container" onClickClose={() => {this.onCloseLoot()}}>
                        <ContentBlock>
                            <ContentBlock.Content style={{overflowY:'scroll', maxHeight:'1080px'}}>
                                <LootList 
                                    data={this.state.selectionLoot} 
                                    onClick={(e,l) => {this.onLootSelected(e,l)}} 
                                />
                            </ContentBlock.Content>
                        </ContentBlock>
                    </PromptOverlay> : null }
                { this.state.confirmDeleteLoot ? <ConfirmationPrompt onClickYes={(e) => {this.onDeleteLootConfrimation()}} onClickNo={(e) => {this.onDeleteDeny()}} title={"Are you sure you would like to remove " + this.state.deleteLoot?.name +" from the Loot Table?"} /> : null}
                { this.state.loading ? <LoadingSpinner /> :
                    <LootTableView 
                        table={table}
                        state={["Edit","Delete"]}
                        onAddNewLoot={(e, t) => { this.onAddNewLoot() }}
                        onAddLoot={(e, t) => { this.onAddLoot() }}
                        onMinQuantityEdit={(d, v) => { this.onMinQuantityEdit(d, v) }}
                        onMaxQuantityEdit={(d, v) => { this.onMaxQuantityEdit(d, v) }}
                        onEditLoot={(e, d) => { this.onEditLoot(e, d) }}
                        onDeleteLoot={(e, d) => { this.onDeleteLoot(e, d) }}
                        onEditName={(d, v) => { this.onEditName(d, v)}}
                        onVisibilityEdit={(d, v) => { this.onVisibilityEdit(d, v)}}
                        onSubmit={() => { this.storeTable() }}
                        onRevert={() => { this.loadData() }}
                        quantityValidator={this.lootTableQuanitityValidator}
                        invalidQuantity={this.state.invalidQuantity}
                    />
                }
            </div>
        );
    }

}

/**
 * Function wrapper to make use of hooks
 * @param {*} props 
 * @returns component
 */
export default function LootTableEditPage(props: IProps){
    let params = useParams();
    return <LootTableEditClass id={params.id ? params.id : ""}/>
}