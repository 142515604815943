import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import LootHistoryView from '../../components/loot/LootHistoryView';
import LoadingSpinner from '../../components/system/LoadingSpinner';
import { setUser } from '../../controllers/redux/reducers/UserReducer';
import { RootState } from '../../controllers/redux/Store';
import LootContainer from '../../model/loot/LootContainer';
import { TITLE } from '../../utils/Constants';
import { getAllLootContainers } from '../../utils/LootHelper';

//************************************************************************************************ 
//*  State & Props
//************************************************************************************************/
interface IProps extends PropsFromRedux {}

interface IState {
    loading: boolean;
    history: LootContainer[];
}

//************************************************************************************************ 
//*  Redux
//************************************************************************************************/
const mapState = (state: RootState) => ({
    user: state.userState.user
});

const mapDispatch = {
    setUser: setUser
}

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

//************************************************************************************************ 
//*  Component
//************************************************************************************************/
class LootHistoryPage extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading:true,
            history:[]
        }
        document.title = TITLE.LOOT_HISTORY;
    }

    componentDidMount(){
        if(this.props.user) {
            getAllLootContainers(this.props.user).then((history: LootContainer[]) => {
                if (history) history = history.reverse();
                this.setState({ history: history, loading: false});
            });
        } else {
            this.setState({ history: [], loading: false});
        }
        
    }

    render() {
        return (
            <div className="route-page">
                { this.state.loading ? <LoadingSpinner /> : null }
                <LootHistoryView data={this.state.history} />
            </div>
        );
    }

}

export default connector(LootHistoryPage);