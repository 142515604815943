import React from 'react';
import { connect, ConnectedProps } from "react-redux";
import LootTableSelector from '../components/loot/LootTableSelector';
import WelcomeBanner from '../components/system/info/WelcomeBanner';
import { setUser } from "../controllers/redux/reducers/UserReducer";
import { RootState } from "../controllers/redux/Store";
import LootTable from "../model/loot/LootTable";
import { withRouter, WithRouterProps } from '../utils/AppUtilities';
import { TITLE } from '../utils/Constants';
import { getAllLootTables } from '../utils/LootHelper';

//************************************************************************************************ 
//*  State & Props
//************************************************************************************************/
interface IProps extends PropsFromRedux {}

interface IState {
    tables: LootTable[];
}

//************************************************************************************************ 
//*  Redux
//************************************************************************************************/
const mapState = (state: RootState) => ({
    user: state.userState.user
});

const mapDispatch = {
    setUser: setUser
}

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

//************************************************************************************************ 
//*  Component
//************************************************************************************************/
class HomePage extends React.Component<WithRouterProps<IProps>, IState> {

    constructor(props: WithRouterProps<IProps>) {
        super(props);
        this.state = {
            tables: []
        }
        document.title = TITLE.HOME;
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        // on user change
        if(prevProps.user !== this.props.user) {
            this.loadData();
        }
    }

    loadData() {
        getAllLootTables(this.props.user).then((tables: LootTable[]) => {
            if (tables) this.setState({ tables: tables});
            else this.setState({ tables: []});
        });
    }

    render() {
        return (
            <div>
                <div className="route-page container-fluid">
                    <WelcomeBanner />
                    <LootTableSelector data={this.state.tables} title={"Roll for Loot & Treasure"} subtitle={"Manage Loot Tables"} user={this.props.user} navigate={this.props.navigate} />
                </div>
            </div>
        );
    }

}

export default connector(withRouter<IProps>(HomePage));