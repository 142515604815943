import React, { CSSProperties } from 'react';

interface IProps {

}

interface IState {
    
}

export default class BuyMeACoffeeButton extends React.Component<IProps, IState> {

    render() {
        return (
            <div style={style}>
                <a href="https://www.buymeacoffee.com/8BitAlex"><img alt='Buy Me a Coffee Link' src="https://img.buymeacoffee.com/button-api/?text=Support Alderman RPG&emoji=&slug=8BitAlex&button_colour=FFDD00&font_colour=000000&font_family=Cookie&outline_colour=000000&coffee_colour=ffffff" /></a>
            </div>
        );
    }

}

const style: CSSProperties = {
    width:'100%',
    textAlign:'center',
    marginBottom:'16px'
}