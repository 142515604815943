import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import React, { CSSProperties } from 'react';
import { FaWeightHanging } from 'react-icons/fa';
import { GiTwoCoins } from 'react-icons/gi';
import ContentBlock from '../../components/system/ContentBlock';
import EditableField from '../../components/system/EditableField';
import PersistanceHeader from '../../components/system/PersistanceHeader';
import { SYSTEM_LOOT_COLLECTION_KEY } from '../../controllers/datastore/Datastore';
import { LootWeightValidator } from '../../controllers/LootValidators';
import { NumberValidator, TextValidator } from '../../controllers/validators/CommonValidators';
import Loot from '../../model/loot/Loot';
import { getRarityOptions } from '../../model/loot/Rarity';
import { getUserDisplayName, isDevEnv } from '../../utils/AppUtilities';
import { COLOR, ROUTE, SCREEN } from '../../utils/Constants';
import { saveAsSystemData } from '../../utils/LootHelper';
import ActionButton from '../system/buttons/ActionButton';
import { ShareButton } from '../system/buttons/CommonButtons';

interface IProps {
    className?: string;
    style?: CSSProperties;
    loot: Loot;
    subtitle?: string;
    prompt?: boolean;
    edit?: boolean;
    onEditName?: (event: any) => void;
    onEditCategory?: (event: any) => void;
    onEditRarity?: (event: any) => void;
    onEditValue?: (event: any) => void;
    onEditWeight?: (event: any) => void;
    onEditDescription?: (event: any) => void;
    onPrivacyEdit?: (event: any) => void;
    onSubmit?: (event: any) => void;
    onRevert?: (event: any) => void;
}

interface IState {
    isDirty: boolean;
    user: User | undefined;
    ownerName: string;
    screenWidth: number;
}

export default class LootDetailsEdit extends React.Component<IProps, IState> {

    // text
    NAME_TEXT = "Name";
    CATEGORY_TEXT = "Category";
    RARITY_TEXT = "Rarity";
    VALUE_TEXT = "Value";
    WEIGHT_TEXT = "Weight";
    DESCRIPTION_TEXT = "Description";

    // validators
    lootNameValidator = new TextValidator(undefined, this.NAME_TEXT);
    lootCategoryValidator = new TextValidator(undefined, this.CATEGORY_TEXT);
    lootValueValidator = new NumberValidator(0, 10000000000);
    lootWeightValidator = new LootWeightValidator();
    lootDescriptionValidator = new TextValidator(3000, this.DESCRIPTION_TEXT);

    constructor(props: IProps) {
        super(props);
        this.state = {
            isDirty: false,
            user: undefined,
            ownerName: "",
            screenWidth: window.innerWidth
        }
    }

    componentDidMount() {
        // auth
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // logged in
                this.setState({ user: user });
            } else {
                // logged out
                this.setState({ user: undefined });
            }
        });
        // Get owner display name
        const loot = this.props.loot;
        if (loot.OWNER_ID) {
            getUserDisplayName(loot.OWNER_ID).then((name) => {
                this.setState({ ownerName: name });
            });
        } else {
            this.setState({ ownerName: "Alderman RPG" })
        }
        // resize
        window.addEventListener('resize', () => {
            this.setState({
                screenWidth: window.innerWidth
            });
        }, false);
    }

    getRarityColor() {
        if (this.props.loot) {
            return this.props.loot.getRarityColor();
        } else {
            return '#fff'
        }
    }

    // event handlers

    onEdit(event: any, valid: boolean, func?: Function) {
        this.setState({ isDirty: true });
        if (valid && func) func(event);
    }

    onEditName(event: any, valid: boolean) {
        this.onEdit(event, valid, this.props.onEditName);
    }

    onEditCategory(event: any, valid: boolean) {
        this.onEdit(event, valid, this.props.onEditCategory);
    }

    onEditRarity(event: any, valid: boolean) {
        this.onEdit(event, valid, this.props.onEditRarity);
    }

    onEditValue(event: any, valid: boolean) {
        this.onEdit(event, valid, this.props.onEditValue);
    }

    onEditWeight(event: any, valid: boolean) {
        this.onEdit(event, valid, this.props.onEditWeight);
    }

    onEditDescription(event: any, valid: boolean) {
        this.onEdit(event, valid, this.props.onEditDescription);
    }

    onPrivacyEdit(event: any, valid: boolean) {
        this.onEdit(event, valid, this.props.onPrivacyEdit);
    }

    onSubmit(event: any) {
        this.setState({ isDirty: false });
        this.props.onSubmit?.(event);
    }

    onRevert(event: any) {
        this.setState({ isDirty: false });
        this.props.onRevert?.(event);
    }

    // render

    render() {
        const loot = this.props.loot;
        const subtitle = this.props.subtitle ? this.props.subtitle : "Loot";

        const rarityStyle = {
            color: this.getRarityColor()
        }

        return (
            <div className={this.props.className} style={this.props.style}>
                <ContentBlock>
                    {this.props.prompt ? null : <ContentBlock.Title title={loot.name} subtitle={subtitle} editable={this.props.edit} onEdit={(e, v) => { this.onEditName(e, v) }} validator={this.lootNameValidator} />}
                    <ContentBlock.Content>
                        {/* Prompt Title */}
                        {this.props.prompt ? <div><h2>{loot.name}</h2><h3>{subtitle}</h3><hr /></div> : null}

                        {/* Top Bar */}
                        <div className='row'>
                            <div className='col-md-6'>
                                {/* Owner Text */}
                                {this.state.ownerName ? <h3>Created by {this.state.ownerName}</h3> : null}
                            </div>
                            {/* Button Bar */}
                            <div className={ this.state.screenWidth <= SCREEN.MEDIUM ? 'col-md-6 order-first' : 'col-md-6' } style={{ textAlign: 'right' }}>
                                {/* Share Button */}
                                {loot.privacy === "Private" || !this.state.user ? null : <ShareButton style={buttonStyle} link={window.location.host + ROUTE.LOOT_VIEW + loot.UUID} />}
                                {isDevEnv() ?
                                    <ActionButton style={buttonStyle} >
                                        {/* Admin Menu Items */}
                                        <>
                                            <ActionButton.Menu text="(A) Save as System Data" onClick={(e) => { saveAsSystemData(loot, SYSTEM_LOOT_COLLECTION_KEY) }} />
                                        </>
                                    </ActionButton> : null}
                            </div>
                        </div>



                        {/* Privacy */}
                        {this.props.edit ? <div className="a-dropdown mb-4" style={{}}><EditableField dropdown dropdownValues={['Public', 'Private']} text={loot.privacy} onEdit={(e, v) => { this.onPrivacyEdit(e, v) }}>Privacy:</EditableField></div> : null}
                        {/* Category */}
                        <div className="field"><h5 style={fieldStyle}>{this.CATEGORY_TEXT}:</h5> {this.props.edit ? <EditableField text={loot.category} onEdit={(e, v) => { this.onEditCategory(e, v) }} validator={this.lootCategoryValidator} /> : <span>{loot.category}</span>}</div>
                        {/* Rarity */}
                        <div className="field"><h5 style={fieldStyle}>{this.RARITY_TEXT}:</h5> <span style={rarityStyle}>{this.props.edit ? <EditableField className="a-dropdown" dropdownColor={this.getRarityColor()} text={loot.getRarityText()} dropdown dropdownValues={getRarityOptions()} onEdit={(e, v) => { this.onEditRarity(e, v) }} /> : loot.getRarityText()}</span></div>
                        {/* Value */}
                        <div className="field"><h5 style={fieldStyle}>{this.VALUE_TEXT}:</h5> {this.props.edit ? <EditableField text={loot.value.toString()} onEdit={(e, v) => { this.onEditValue(e, v) }} validator={this.lootValueValidator} /> : <span className="currency"><GiTwoCoins className="icon" /> {loot.value}</span>}</div>
                        {/* Weight */}
                        <div className="field"><h5 style={fieldStyle}>{this.WEIGHT_TEXT} (lbs):</h5> {this.props.edit ? <EditableField text={loot.weight.toString()} onEdit={(e, v) => { this.onEditWeight(e, v) }} validator={this.lootWeightValidator} /> : <span style={{ color: COLOR.SECONDARY_BRICK }}><FaWeightHanging className="icon" /> {loot.weight} lbs</span>}</div>
                        {/* Description */}
                        <div className="field"><div><h5>{this.DESCRIPTION_TEXT}:</h5></div>{this.props.edit ? <EditableField textarea resize text={loot.description} onEdit={(e, v) => { this.onEditDescription(e, v) }} validator={this.lootDescriptionValidator} /> : loot.description}</div>
                        {this.props.edit ? <PersistanceHeader isDirty={this.state.isDirty} onRevert={(e) => { this.onRevert(e) }} onSubmit={(e) => { this.onSubmit(e) }} /> : null}
                    </ContentBlock.Content>
                </ContentBlock>
            </div>
        );
    }

}

const fieldStyle: CSSProperties = {
    display: 'inline'
}

const buttonStyle = {
    display: 'inline-block',
    margin: '0',
    width: '32px',
    height: '32px'
}