import React from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LootDetailsEdit from '../../components/loot/LootDetailsEdit';
import LoadingSpinner from '../../components/system/LoadingSpinner';
import { LOOT_COLLECTION_KEY } from '../../controllers/datastore/Datastore';
import DatastoreKey from '../../model/datastore/DatastoreKey';
import { PrivacyMode } from '../../model/interfaces/IPublicAccess';
import Loot from '../../model/loot/Loot';
import { Rarity } from '../../model/loot/Rarity';
import { TITLE } from '../../utils/Constants';
import { getLoot } from '../../utils/LootHelper';

interface IProps {
    id: string;
}

interface IState {
    loot: Loot;
    loading: boolean;
    confirmDelete: boolean;
}

class LootEditPageClass extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            loot: new Loot("",0),
            loading:true,
            confirmDelete: false
        }
        document.title = TITLE.LOOT_EDIT;
    }

    componentDidMount(){
        this.loadLoot();
    }

    loadLoot(){
        this.setState({loading:true});
        getLoot(new DatastoreKey(LOOT_COLLECTION_KEY, this.props.id)).then((l: Loot) => {
            if(l) this.setState({ loot:l, loading:false })
        });
    }

    onEditName(value: string){
        const loot = this.state.loot;
        loot.name = value;
        this.setState({ loot: loot });
    }

    onEditCategory(value: string){
        const loot = this.state.loot;
        loot.category = value;
        this.setState({ loot: loot });
    }

    onEditRarity(value: string){
        const loot = this.state.loot;
        // @ts-ignore
        loot.rarity = Rarity[value];
        this.setState({ loot: loot });
    }
    
    onEditValue(value: string){
        const loot = this.state.loot;
        loot.value = Number.parseInt(value);
        this.setState({ loot: loot });
    }

    onEditWeight(value: number) {
        const loot = this.state.loot;
        loot.weight = value;
        this.setState({ loot: loot });
    }

    onEditDescription(value: string){
        const loot = this.state.loot;
        loot.description = value;
        this.setState({loot:loot});
    }

    onPrivacyEdit(value: PrivacyMode){
        const loot = this.state.loot;
        loot.privacy = value;
        this.setState({loot:loot});
    }

    storeLoot(){
        const loot = this.state.loot;
        loot.store().then(() => {
            toast("Loot Saved");
            this.loadLoot();
        });
    }

    // Render

    render() {
        return (
            <div className="route-page">
                { this.state.loading ? <LoadingSpinner /> :
                    <LootDetailsEdit
                        loot={this.state.loot}
                        subtitle="Edit Loot"
                        edit
                        onRevert={(e) => {this.loadLoot()}}
                        onSubmit={(e) => {this.storeLoot()}}
                        onEditName={(v) => {this.onEditName(v)}}
                        onEditCategory={(v) => { this.onEditCategory(v) }}
                        onEditRarity={(v)=>{this.onEditRarity(v)}}
                        onEditValue={(v)=>{this.onEditValue(v)}}
                        onEditWeight={(v) => { this.onEditWeight(v) }}
                        onEditDescription={(v) => { this.onEditDescription(v)}}
                        onPrivacyEdit={(v) => { this.onPrivacyEdit(v)}}
                    />
                }
            </div>
        );
    }

}

/**
 * Function wrapper to make use of hooks
 * @param {*} props 
 * @returns component
 */
 export default function LootEditPage(props: IProps){
    let params = useParams();
    return <LootEditPageClass id={params.id ? params.id : ""}/>
}