import { FaEdit, FaMinus, FaPlusCircle, FaSave, FaShareSquare, FaTrash, FaUndo } from 'react-icons/fa';
import { toast } from 'react-toastify';
import CustomButton, { IProps as CustomButtonProps } from './CustomButton';

interface IProps extends CustomButtonProps {}

/**
 * Props:
 * - style:
 * - className:
 * - onClick:
 * - tooltip:
 * @param {*} props 
 * @returns 
 */
export function AddButton(props: IProps) {

    const name = "Add";

    return (
        <CustomButton
            {...props}
            variant="primary"
            tooltip={props.tooltip ? props.tooltip : name}
        >
            <FaPlusCircle className="icon" /> {props.children}
        </CustomButton>
    );

}

/**
 * Props:
 * - style:
 * - className:
 * - onClick:
 * - tooltip:
 * @param {*} props 
 * @returns 
 */
export function EditButton(props: IProps) {

    const name = "Edit";

    const style = {
        marginLeft: '3px'
    }

    return (
        <CustomButton
            {...props}
            variant="primary"
            tooltip={props.tooltip ? props.tooltip : name}
        >
            <span style={style}><FaEdit className="icon" /> {props.children}</span>
        </CustomButton>
    );

}

/**
 * Props:
 * - style:
 * - className:
 * - onClick:
 * - tooltip:
 * @param {*} props 
 * @returns 
 */
export function DeleteButton(props: IProps) {

    const name = "Delete";

    return(
        <CustomButton 
            {...props}
            variant="danger"
            tooltip={props.tooltip ? props.tooltip : name}
        >
            <FaTrash className="icon" /> {props.children}
        </CustomButton>
    );

}

/**
 * Props:
 * - style:
 * - className:
 * - onClick:
 * - tooltip:
 * @param {*} props 
 * @returns 
 */
export function RemoveButton(props: IProps) {

    const name = "Remove";

    return(
        <CustomButton 
            {...props}
            variant="danger"
            tooltip={props.tooltip ? props.tooltip : name}
        >
            <FaMinus className="icon" /> {props.children}
        </CustomButton>
    );

}

/**
 * Props:
 * - style:
 * - className:
 * - onClick:
 * - tooltip:
 * @param {*} props 
 * @returns 
 */
export function SubmitButton(props: IProps) {

    const name = "Submit";

    return (
        <CustomButton 
            {...props}
            variant="primary"
            tooltip={props.tooltip ? props.tooltip : name}
        >
            <FaSave className="icon" /> {props.children}
        </CustomButton>
    );

}

/**
 * Props:
 * - style:
 * - className:
 * - onClick:
 * - tooltip:
 * @param {*} props 
 * @returns 
 */
export function RevertButton(props: IProps) {

    const name = "Revert";

    return (
        <CustomButton
            {...props}
            variant="secondary"
            tooltip={props.tooltip ? props.tooltip : name}
        >
            <FaUndo className="icon" /> {props.children}
        </CustomButton>
    );

}

interface ShareProps extends IProps {
    link: string;
}

export function ShareButton(props: ShareProps) {

    const name = "Share";

    return (
        <CustomButton
            {...props}
            variant="info"
            size='lg'
            tooltip={props.tooltip ? props.tooltip : name}
            onClick={() => {
                navigator.clipboard.writeText(props.link);
                toast("Link Copied")
            }}
        >
            <FaShareSquare className="icon" /> {props.children}
        </CustomButton>
    );

}