import IValidator from "../../model/interfaces/IValidator";
import { REGEX } from "../../utils/Constants";

export class TextValidator implements IValidator {

    constructor(maxChars?: number, textType?: string){
        if(maxChars) this.maxChars = maxChars;
        this.validationMsg = this.getValidationMsg(textType);
    }

    maxChars: number = 50;
    validationMsg: string = this.getValidationMsg();

    getValidationMsg(textType?: string){
        const type: string = textType ? textType : "Text";
        return type + " must be 1 to " + this.maxChars + " characters long and only contain Alphanumeric characters";
    }

    validate(value: any){
        if (value.length >= this.maxChars || !REGEX.US_KEYBOARD.test(value)) return false;
        return true;
    }

}

export class NumberValidator implements IValidator {

    constructor(min?: number, max?: number, textType?: string){
        if(min) this.min = min;
        if(max) this.max = max;
        this.validationMsg = this.getValidationMsg(textType);
    }

    min: number = 0;
    max: number = 100;
    validationMsg: string = "";

    getValidationMsg(textType?: string){
        const type: string = textType ? textType : "Value";
        return type + " must be an Integer and between " + this.min + " and " + this.max;
    }

    validate(value: any){
        const num = Number(value);
        if (!num || !Number.isInteger(num) || num < this.min || num > this.max) return false;
        return true;
    }

}