import React from 'react';
import { Alert } from 'react-bootstrap';
import { connect, ConnectedProps } from "react-redux";
import LoginForm from "../../components/auth/LoginForm";
import SignUpForm from '../../components/auth/SignUpForm';
import ContentBlock from "../../components/system/ContentBlock";
import CustomImg from "../../components/system/CustomImg";
import { setUser } from "../../controllers/redux/reducers/UserReducer";
import { RootState } from "../../controllers/redux/Store";
import { withRouter, WithRouterProps } from '../../utils/AppUtilities';
import { ROUTE, SCREEN, TITLE } from '../../utils/Constants';

//************************************************************************************************ 
//*  State & Props
//************************************************************************************************/
interface IProps extends PropsFromRedux {
    signUp?: boolean;
}

interface IState {
    screenWidth: number;
}

//************************************************************************************************ 
//*  Redux
//************************************************************************************************/
const mapState = (state: RootState) => ({
    user: state.userState.user
});

const mapDispatch = {
    setUser: setUser
}

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

//************************************************************************************************ 
//*  Component
//************************************************************************************************/
const BANNER_ALT_TEXT = "Alderman Login Banner. Be Part of a Greater Community. Data Stored in the Cloud. Share your Work Publicly.";

class AuthPage extends React.Component<WithRouterProps<IProps>, IState> {

    constructor(props: WithRouterProps<IProps>) {
        super(props);
        this.state = {
            screenWidth: window.innerWidth
        }
        document.title = TITLE.LOGIN;
    }

    componentDidMount() {
        // resize
        window.addEventListener('resize', () => {
            this.setState({
                screenWidth: window.innerWidth
            });
        }, false);
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if(prevProps.user !== this.props.user) {
            this.props.navigate(ROUTE.HOME);
        }
    }

    render() {

        const title = this.props.signUp ? "Create an account" : "Login";

        return (
            <div className="route-page">
                { this.props.user != null ?
                    <Alert variant="success"> You are successfully logged in. </Alert> :
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 col-xl-7" >
                                <CustomImg url="/img/aldermanlogin1200.jpg" mobileUrl="/img/aldermanlogin1080.jpg" alt={BANNER_ALT_TEXT} screen={SCREEN.MEDIUM} />
                            </div>
                            <div className="col-lg-12 col-xl-5 align-self-center">
                                <ContentBlock>
                                    <ContentBlock.Title title={title} />
                                    <ContentBlock.Content>
                                        { this.props.signUp ? <SignUpForm /> : <LoginForm /> }
                                    </ContentBlock.Content>
                                </ContentBlock>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }

}

export default connector(withRouter<IProps>(AuthPage));