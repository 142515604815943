import { plainToInstance } from 'class-transformer';
import { LOOT_COLLECTION_KEY } from '../../controllers/datastore/Datastore';
import { Storable } from '../../model/Storable';
import IPublicAccess, { PrivacyMode } from '../interfaces/IPublicAccess';
import { getRarityColor, getRarityText, Rarity } from './Rarity';

/**
 * Loot
 */
export default class Loot extends Storable implements IPublicAccess {
    
    description: string = "";
    value: number = 0;
    rarity: Rarity = Rarity.Common;
    category: string = "";
    weight: number = 0.0;

    privacy: PrivacyMode = "Public";

    //************************************************************************************************ 
    //*  Init
    //************************************************************************************************/

    constructor(name: string, rarity: Rarity) {
        super(name, LOOT_COLLECTION_KEY);
        this.rarity = rarity;
        this.setRarity(rarity);
    }

    //************************************************************************************************ 
    //*  Public Functions
    //************************************************************************************************/

    setRarity(rarity: Rarity){
        this.rarity = rarity;
    }

    getRarityText(){
        return getRarityText(this.rarity);
    }

    getRarityColor(){
        return getRarityColor(this.rarity);
    }

    static toClass(obj: Object): Loot {
        return plainToInstance(Loot, obj);
    }

}