import { plainToInstance, Transform, Type } from 'class-transformer';
import { LOOT_CONTAINER_COLLECTION_KEY } from '../../controllers/datastore/Datastore';
import { Storable } from '../../model/Storable';
import Loot from './Loot';

export default class LootContainer extends Storable {

    // Properties
    @Transform(({value}) => {return convertTimestamp(value)})
    timestamp: Date;
    // these are not referenced because we want a snapshot of how they were when they were generated
    @Type(() => Loot)
    loot: Loot[];
    lootTableName: string;

    constructor(loot: Loot[], lootTableName: string, timestamp: Date){
        super("Loot Container " + timestamp, LOOT_CONTAINER_COLLECTION_KEY);
        this.timestamp = timestamp;
        this.loot = loot;
        this.lootTableName = lootTableName;
    }

    getTimestamp(){
        return this.timestamp;
    }

    static toClass(obj: Object): LootContainer {
        return plainToInstance(LootContainer, obj);
    }

}

/**
 * Converts firestore timestamp or Date object to Date
 * @param value timestamp value
 * @returns Date
 */
function convertTimestamp(value: any): Date{
    if(value.toDate) return value.toDate();
    return new Date(value);
}