import React, { CSSProperties } from 'react';
import { FaTimes } from 'react-icons/fa';
import { COLOR, SCREEN } from '../../../utils/Constants';

export interface IProps {
    className?: string;
    style?: CSSProperties;
    children?: React.ReactNode;
    hideClose?: boolean;
    onClickClose?: (event: any) => void;
}

interface IState {
    isMobile: boolean;
}

export default class PromptOverlay extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            isMobile: window.innerWidth < SCREEN.MEDIUM
        }
    }
    
    componentDidMount(){
        window.addEventListener('resize',this, false);
    }
    
    componentWillUnmount(){
        window.removeEventListener("resize",this);
    }

    handleEvent(event: any) {
        switch(event.type){
            case 'resize':
                this.setState({
                    isMobile: window.innerWidth < SCREEN.MEDIUM
                });
        }
    }

    render() { 

        const style: CSSProperties = {
            position:'fixed',
            top:'50%',
            left:'50%',
            transform:'translate(-50%, -50%)',
            height:'100%',
            width:'100%',
            maxWidth:'1920px',
            maxHeight:'1080px',
            padding: this.state.isMobile ? '100px 8px' : '100px 64px',
            overflowY:'auto'
        }

        const closeBtnStyle: CSSProperties = {
            position:'fixed',
            right: this.state.isMobile ? '24px' : '32px',
            top:'76px',
            zIndex:2,
            color:COLOR.PRIMARY_BRICK,
            cursor:'pointer'
        }

        return (
            <div style={containerStyle} onClick={this.props.onClickClose}>
                { this.props.hideClose ? null : <FaTimes style={closeBtnStyle} size="2em" /> }
                <div className={this.props.className} style={{...this.props.style,...style}}>
                    <div onClick={(e) => e.stopPropagation()}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }

}

const containerStyle: CSSProperties = {
    position:'fixed',
    height:'100vh',
    width:'100vw',
    top:'0',
    left:'0',
    background:'rgb(0,0,0,.6)',
    zIndex:1
}