import { User } from "firebase/auth";
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaCaretRight, FaDiceD20 } from 'react-icons/fa';
import { NavigateFunction } from "react-router-dom";
import ContentBlock from '../../components/system/ContentBlock';
import SelectorFactory from '../../model/interfaces/ISelector';
import Loot from "../../model/loot/Loot";
import LootContainer from '../../model/loot/LootContainer';
import LootTable from "../../model/loot/LootTable";
import { Storable } from "../../model/Storable";
import { trimDisplayText } from '../../utils/AppUtilities';
import { EVENT, ROUTE, SCREEN } from '../../utils/Constants';
import { logAnalyticsEvent } from "../../utils/Logger";
import CustomButton from '../system/buttons/CustomButton';
import LootList from './LootList';

interface IProps {
    data: LootTable[];
    title: string;
    subtitle: string;
    user: User | null;
    navigate: NavigateFunction;
}

interface IState {
    loading: boolean;
    table: LootTable | undefined;
    selectedItems: Loot[];
    isMobile: boolean;
    timestamp: string;
    history: LootContainer | undefined;
}

export default class LootTableSelector extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false,
            table: undefined,
            selectedItems: [],
            isMobile: window.innerWidth < SCREEN.MEDIUM,
            timestamp:"",
            history: undefined
        }
    }

    componentDidMount(){
        // resize
        window.addEventListener('resize', () => {
            this.setState({
                isMobile: window.innerWidth < SCREEN.MEDIUM
            });
        }, false);
    }

    createLootTableDropdownItems(){
        const items = [];
        const data = this.props.data;

        if(data && data.length > 0){
          for(const selectable of data){
            const el = <Dropdown.Item key={selectable.name} onClick={() => {this.setState({table:selectable})}}>{selectable.name}</Dropdown.Item>;
            items.push(el);
          }
        } else {
          items.push(<Dropdown.Item key="no-table">No Tables</Dropdown.Item>);
        }
    
        return items;
    }

    select(table?: LootTable) {
        const now = new Date();

        if(table?.name){
            Storable.resolveReferences(table, true).then(() => {
                // Roll Loot
                let selected = SelectorFactory().select(table);
                this.setState({selectedItems:selected, timestamp:now.toLocaleString()});
                // Save History
                if(this.props.user) {
                    const history = new LootContainer(selected, table.name, now);
                    history.store();
                }

                // Log Event
                logAnalyticsEvent(EVENT.SELECT_FROM_LOOT_TABLE, {
                    user_id: this.props.user ? this.props.user.uid : "",
                    user_email: this.props.user ? this.props.user.email : "",
                    timestamp: now
                });
            });
        }
    }

    onShowHistory(){
        this.props.navigate(ROUTE.LOOT_HISTORY);
    }

    render() {

        // reactive
        var mobileClasses = "px-2";
        if(this.state.isMobile) mobileClasses ="px-0";

        return (            
            <div>
                <ContentBlock>
                    <ContentBlock.Title title={this.props.title} subtitle={this.props.subtitle} subtitleHref={ROUTE.LOOT_TABLE} />
                    <ContentBlock.Content>
                        <div className={"row mb-1 "+mobileClasses}>
                            <div className="col-md-4 col-xl-3 mb-1 px-1">
                                <Dropdown >
                                    <Dropdown.Toggle className="a-button" style={dropdownStyle} variant="primary">
                                        {this.state.table?.name ? trimDisplayText(this.state.table.name,26) : "Select Loot Table"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu variant="dark">
                                        {this.createLootTableDropdownItems()}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="col-md-2 col-xl-1 mb-1 px-1">
                                <CustomButton variant="secondary" style={{width:'100%'}} onClick={(e) => this.select(this.state.table)}><FaDiceD20 className="icon" /> Roll</CustomButton>
                            </div>
                            <div className="col-md-3 col-xl-6" />
                            <div className="col-md-3 col-xl-2 mb-1 px-1">
                                <CustomButton 
                                    style={{ width: '100%' }} 
                                    variant="secondary" 
                                    disabled={this.props.user === null}
                                    tooltip={this.props.user ? null : "Requires user account"}
                                    onClick={() => { this.onShowHistory() }}
                                    lock={this.props.user === null}>
                                        History
                                        <FaCaretRight className="icon" />
                                </CustomButton>
                            </div>
                        </div>
                        <h3>Last Roll: {this.state.timestamp}</h3>
                        <hr />
                        <div className="row">
                            <LootList data={this.state.selectedItems} />
                        </div>
                    </ContentBlock.Content>
                </ContentBlock>
            </div>
        );
    }

}

const dropdownStyle = {
    width: '100%'
}