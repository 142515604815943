import React, { CSSProperties } from 'react';
import NothingHere from '../components/system/NothingHere';
import { getRandomInt } from '../utils/AppUtilities';
import { TITLE } from '../utils/Constants';

interface IProps {
    
}

interface IState {
    quote: string;
}

export default class NoRoutePage extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            quote:""
        }
        document.title = TITLE.HOME;
    }

    componentDidMount(){
        fetch('assets/quotes.json').then((response) => {
            response.json().then((data) => {
                const quotes = data.data;
                const quote = quotes[getRandomInt(0,quotes.length)];
                this.setState({quote:quote});
            });
        });
    }

    render() {
        return (
            <div className="route-page">
                <NothingHere />
                <h2 style={quoteStyle}>{this.state.quote}</h2>
            </div>
        );
    }

}

const quoteStyle: CSSProperties = {
    margin:"auto",
    width:"75%",
    height:"100%",
    padding: "150px 0",
    textAlign:"center"
}