import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Outlet, Route, Routes } from "react-router-dom";
import { toast, ToastOptions } from 'react-toastify';
import './App.scss';
import AToast from './components/system/AToast';
import Footer from './components/system/Footer';
import Header from './components/system/Header';
import { ACCEPT_PRIVACY_NOTICE } from './controllers/datastore/Datastore';
import { setUser } from './controllers/redux/reducers/UserReducer';
import { RootState } from './controllers/redux/Store';
import AuthPage from './pages/auth/AuthPage';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import ProfilePage from './pages/auth/ProfilePage';
import FoundersPage from './pages/FoundersPage';
import HomePage from './pages/HomePage';
import HowItWorksPage from './pages/HowItWorksPage';
import LootEditPage from './pages/loot/LootEditPage';
import LootHistoryPage from './pages/loot/LootHistoryPage';
import LootPage from './pages/loot/LootPage';
import LootTableEditPage from './pages/loot/LootTableEditPage';
import LootTablePage from './pages/loot/LootTablePage';
import LootTableViewPage from './pages/loot/LootTableViewPage';
import LootViewPage from './pages/loot/LootViewPage';
import NoRoutePage from './pages/NoRoutePage';
import PrivacyPage from './pages/PrivacyPage';
import TermsPage from './pages/TermsPage';
import { ROUTE, SCREEN, TEXT } from './utils/Constants';
import { onAuthChange } from './utils/FirebaseUtils';

//************************************************************************************************ 
//*  State & Props
//************************************************************************************************/
interface IProps extends PropsFromRedux {}
interface IState {}

//************************************************************************************************ 
//*  Redux
//************************************************************************************************/
const mapState = (state: RootState) => ({
    user: state.userState.user
});

const mapDispatch = {
    setUser: setUser
}

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

//************************************************************************************************ 
//*  Setup Stuff
//************************************************************************************************/

// Router Top-Level Layout
function Layout() {
    return (
        <div>
            <Header />
            <div className='content'>
                <Outlet />
            </div>
            <Footer />
        </div>
    );
}

// Privacy Notice
const privacyNoticeToastOptions: ToastOptions = {
    hideProgressBar: true,
    autoClose: false,
    position: 'bottom-left',
    style: {
        width: window.innerWidth < SCREEN.SMALL ? '100vw' : 'calc(100vw - 42px)'
    },
    onClose: () => {
        localStorage.setItem(ACCEPT_PRIVACY_NOTICE, 'true');
    }
}

//************************************************************************************************ 
//*  Render App
//************************************************************************************************/
class App extends React.Component<IProps, IState> {

    componentDidMount() {
        // show privacy notice
        if (!localStorage.getItem(ACCEPT_PRIVACY_NOTICE)) {
            toast.clearWaitingQueue();
            toast(TEXT.PRIVACY_NOTICE, privacyNoticeToastOptions);
        }
        // redux: set user
        onAuthChange((user) => this.props.setUser(user));
    }

    render() {
        return (
            <div className="app">
                <AToast />
                <Routes>
                    <Route path={ROUTE.HOME} element={<Layout />}>
                        <Route index element={<HomePage />} />
                        <Route path={ROUTE.LOOT_TABLE} element={<LootTablePage />} />
                        <Route path={ROUTE.LOOT_TABLE_EDIT + ":id"} element={<LootTableEditPage id='' />} />
                        <Route path={ROUTE.LOOT_TABLE_VIEW + ":id"} element={<LootTableViewPage id='' />} />
                        <Route path={ROUTE.LOOT} element={<LootPage />} />
                        <Route path={ROUTE.LOOT_EDIT + ":id"} element={<LootEditPage id='' />} />
                        <Route path={ROUTE.LOOT_VIEW + ":id"} element={<LootViewPage id='' />} />
                        <Route path={ROUTE.LOOT_HISTORY} element={<LootHistoryPage />} />
                        <Route path={ROUTE.LOGIN} element={<AuthPage />} />
                        <Route path={ROUTE.FORGOT_PASS} element={<ForgotPasswordPage />} />
                        <Route path={ROUTE.SIGNUP} element={<AuthPage signUp />} />
                        <Route path={ROUTE.HOW_IT_WORKS} element={<HowItWorksPage />} />
                        <Route path={ROUTE.FOUNDERS} element={<FoundersPage />} />
                        <Route path={ROUTE.USER_PROFILE} element={<ProfilePage />} />
                        <Route path={ROUTE.PRIVACY} element={<PrivacyPage />} />
                        <Route path={ROUTE.TOS} element={<TermsPage />} />
                        <Route path={ROUTE.NO_ROUTE} element={<NoRoutePage />} />
                    </Route>                 
                </Routes>
            </div>
        );
    }
    
}

export default connector(App);