import { getAuth, onAuthStateChanged } from 'firebase/auth';
import React from 'react';
import { useParams } from 'react-router-dom';
import LootTableView from '../../components/loot/LootTableView';
import LoadingSpinner from '../../components/system/LoadingSpinner';
import NothingHere from '../../components/system/NothingHere';
import CloudDatastore from '../../controllers/datastore/CloudDatastore';
import { LOOT_TABLE_COLLECTION_KEY, SYSTEM_LOOT_TABLE_COLLECTION_KEY } from '../../controllers/datastore/Datastore';
import { LootTableQuantityValidator } from '../../controllers/LootValidators';
import DatastoreKey from '../../model/datastore/DatastoreKey';
import LootTable from '../../model/loot/LootTable';
import { TITLE } from '../../utils/Constants';

interface IProps {
    id: string;
}

interface IState {
    table: LootTable | undefined;
    loading: boolean;
}

class LootTableViewClass extends React.Component<IProps, IState> {

    lootTableQuanitityValidator = new LootTableQuantityValidator();

    constructor(props: IProps) {
        super(props);
        this.state = {
            table: undefined,
            loading:true
        }
        document.title = TITLE.LOOTTABLE_EDIT;
    }

    componentDidMount(){
        // auth
        onAuthStateChanged(getAuth(), (user) => {
            this.loadData().then(() => {
                this.setState({loading:false});
            });
        });
    }

    async loadData(){
        this.setState({loading:true});

        const store = new CloudDatastore();
        const table = await store.get(new DatastoreKey(LOOT_TABLE_COLLECTION_KEY, this.props.id), true);
        const t: LootTable = LootTable.toClass(table as Object);
        
        if(t){
            this.setState({ table:t, loading: false });
        } else {
            // System Loot
            const table = await store.get(new DatastoreKey(SYSTEM_LOOT_TABLE_COLLECTION_KEY, this.props.id), true);
            const t: LootTable = LootTable.toClass(table as Object);

            if(t){
                this.setState({ table:t, loading: false });
            }
        }
    }

    //************************************************************************************************ 
    //*  Render
    //************************************************************************************************/

    render() {
        return (
            <div className="route-page">
                { this.state.loading ? <LoadingSpinner /> : null }
                { !this.state.table ? <NothingHere /> : <LootTableView table={this.state.table} state={["View"]} /> }
            </div>
        );
    }

}

/**
 * Function wrapper to make use of hooks
 * @param {*} props 
 * @returns component
 */
export default function LootTableViewPage(props: IProps){
    let params = useParams();
    return <LootTableViewClass id={params.id ? params.id : ""}/>
}