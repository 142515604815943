import React from 'react';
import CustomImg from '../CustomImg';

interface IProps {

}

interface IState {

}

export default class WelcomeBanner extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
    }

    render() {

        return (
            <div className='row p-0 m-0'>
                <CustomImg url="/img/banner1920.png" mobileUrl='/img/banner1080.png' alt={altText} />
            </div>
        );
    }

}

const altText = "Alderman RPG Banner. The definitive game masters toolkit for ttrpgs. Create, Customize, and Generate Loot & Treasutre. Enchance Player Immersion. Community Driven. Highly Customizable.";