import { instanceToPlain } from 'class-transformer';
import { getAuth } from 'firebase/auth';
import { collection, deleteDoc, doc, getDoc, getDocs, getFirestore, query, QueryConstraint, setDoc } from 'firebase/firestore';
import DatastoreKey from '../../model/datastore/DatastoreKey';
import { Storable } from '../../model/Storable';
import { log, logError, logWarning } from '../../utils/Logger';
import { Datastore } from './Datastore';

//************************************************************************************************ 
//*  DATASTORE
//************************************************************************************************/

export default class CloudDatastore implements Datastore {

    CLASSNAME = this.constructor.name;

    //************************************************************************************************ 
    //*  Datastore Impl
    //************************************************************************************************/

    /**
     * 
     * @param storable 
     * @param callback 
     */
    async set(storable: Storable) {
        return this.setReferencedObject(storable,storable.STOR_KEY);
    }

    /**
     * 
     * @param key 
     * @param includeReferences If true, resolves object references
     * @param callback 
     */
    async get(key: DatastoreKey, includeReferences?: boolean) {
        return await this.getReferencedObject(key, includeReferences);
    }

    /**
     * 
     * @param key 
     * @param queryConstraints 
     * @returns 
     */
    async getAll(key: DatastoreKey, queryConstraints?: QueryConstraint) {
        const results: Storable[] = [];
        const userId = getAuth().currentUser?.uid;
    
        if (userId) {
            const db = getFirestore();
            var q;
            if(queryConstraints) q = query(collection(db, key.getCollection()), queryConstraints);
            else q = collection(db, key.getCollection());
            log(this.CLASSNAME+" - getAll", "Retrieving All Objects " + key.getCollection());
            const response = await getDocs(q);
            
            // Cast each item as a Storable
            response.forEach((doc) => {
                results.push(doc.data() as Storable);
            });
        } else {
            logWarning("LootHelper - getAllLootTables", "No User ID");
        }
        
        return results;
    }

    /**
     * 
     * @param storable 
     * @param callback 
     */
    async remove(storable: Storable) {
        return this.removeReferencedObject(storable.STOR_KEY);
    }

    //************************************************************************************************ 
    //*  OBJECTS WITH REFERENCES - UTIL
    //************************************************************************************************/

    async getReferencedObject(key: DatastoreKey, includeReferences?: boolean): Promise<Storable | undefined> {
        const db = getFirestore();

        let data: Storable | undefined = undefined;
        try {
            const document = doc(db, key.getCollection(), key.getCollectionKey());
            log(this.CLASSNAME+" - getReferencedObject", "Retrieving Referenced Object " + key.getKey());
            const result = await getDoc(document);
            data = result.data() as Storable;
            if (includeReferences) await Storable.resolveReferences(data,false);
        // @ts-ignore
        } catch(e: any) {
            logError(this.CLASSNAME+" - getReferencedObject", e)
        }
        return data;
    }

    async setReferencedObject(item: Storable, key: DatastoreKey){
        const db = getFirestore();

        if(item){
            try {
                Storable.createReferences(item); //normalize references
                const document = doc(db, key.getCollection(), key.getCollectionKey());
                await setDoc(document, instanceToPlain(item), {merge:true});
                log(this.CLASSNAME+" - setReferencedObject", "Storing Referenced Object " + key.getKey());
            // @ts-ignore
            } catch (e: any) {
                logError(this.CLASSNAME+" - setReferencedObject", e.message)
            }
        }
    }

    async removeReferencedObject(key: DatastoreKey){
        const db = getFirestore();

        try {
            await deleteDoc(doc(db,key.getCollection(),key.getCollectionKey()));
            log(this.CLASSNAME+" - removeReferencedObject", "Removed Referenced Object " + key.getKey());
        } catch(e: any) {
            logError(this.CLASSNAME+" - removeReferencedObject", e);
        }
    }

}