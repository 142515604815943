import React, { CSSProperties } from 'react';
import { SCREEN } from '../../utils/Constants';

interface IProps extends React.HTMLAttributes<HTMLElement> {
    url: string;
    mobileUrl: string;
    alt: string;
    screen?: number;

}

interface IState {
    isMobile: boolean;
}

export default class CustomImg extends React.Component<IProps, IState> {

    constructor(props: IProps){
        super(props);
        this.state = {
            isMobile: window.innerWidth < this.getMaxScreenSize()
        }
    }

    componentDidMount() {
        // resize
        window.addEventListener('resize', () => {
            this.setState({
                isMobile: window.innerWidth < this.getMaxScreenSize()
            });
        }, false);
    }

    getMaxScreenSize() {
        return this.props.screen ? this.props.screen : SCREEN.XLARGE;
    }
    
    render(){

        const bannerStyle: CSSProperties = {
            backgroundImage: this.state.isMobile ? 'url(' + this.props.mobileUrl + ')' : 'url(' + this.props.url + ')',
            width: '100%',
            height:'100%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center'
        }

        return (
            <div
                { ...this.props }
                className="blur col p-0 m-0"
                role="img"
                /* @ts-ignore */
                alt={this.props.alt}
                aria-label={this.props.alt}
                style={ {...bannerStyle, ...this.props.style} }
            >
                {/* Hack to force height to auto adjust. This image is never shown but used to set div height. */}
                <img src={ this.state.isMobile ? this.props.mobileUrl : this.props.url } alt={this.props.alt} style={{height:'100%',width:'100%', visibility:'hidden'}} />
            </div>
        );
    }
}