import React from 'react';
import ContentBlock from '../components/system/ContentBlock';
import InformationBlock from '../components/system/InformationBlock';
import { getRarityColor, getRarityOptions, Rarity } from '../model/loot/Rarity';
import { TEXT, TITLE } from '../utils/Constants';

interface IProps {

}

interface IState {

}

export default class HowItWorksPage extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        document.title = TITLE.HOW_IT_WORKS;
    }

    calculateProb(rarity: Rarity, count: number, total: number, decimal: number = 2){
        return (((rarity * count)/total) * 100).toFixed(decimal);
    }

    getRarityListItems(){
        const results = [];
        for(var i of getRarityOptions()){
            //@ts-ignore
            results.push(<li><span style={{color:getRarityColor(Rarity[i])}}>{i}:</span> {Rarity[i]}</li>);
        }
        return results;
    }

    render() {
        const ex1Total: number = 236;
        const ex2Total: number = 655;
        const ex3Total: number = 50;

        // todo: turn this into graphic banners and stuff
        return (
            <div className="route-page">
                <InformationBlock title="How It Works">
                    <InformationBlock.FullText style={{marginTop: '8px', textAlign:'center'}}>{TEXT.TAGLINE}</InformationBlock.FullText>
                </InformationBlock>
                <ContentBlock>
                    <ContentBlock.Title title='Storage' />
                    <ContentBlock.Content style={blockStyle}>
                        <h4>While Logged In</h4>
                        <p>While you are logged in, everything is stored in the cloud. Things like Loot & Loot Tables are saved to your account.</p>
                        <h4>While Logged Out</h4>
                        <p>While you are logged out, everything is stored locall</p>
                    </ContentBlock.Content>
                </ContentBlock>
                <ContentBlock>
                    <ContentBlock.Title title="Loot & Treasure" />
                    <ContentBlock.Content style={blockStyle}>
                        <h4>Loot & Loot Tables</h4>
                        <p><strong>Loot</strong> are items, treasure, goods, or anything of value that players can aquire. Alderman comes pre-populated with some loot to serve as both examples and a place to start when creating your own content.</p>
                        <p><strong>Loot Tables</strong> are collections of <strong>Loot</strong> that players can pick from. For example, a Loot Table with the name "Small Crate" can represent a collection of possible loot that may be returned when selecting from this table, or in this case a small crate or similar scenario.</p>
                        <p>Discovering <strong>Loot & Treasure</strong> is only limited by your imagination.</p>

                        <h4>Selecting Items</h4>
                        <p>Alderman uses a weighting strategy when selecting <strong>Loot</strong>. Every <strong>Loot</strong> item in a <strong>Loot Table</strong> has a <strong>Rarity</strong> such as <span style={{color:getRarityColor(Rarity.Common)}}>Common</span> or <span style={{color:getRarityColor(Rarity.Rare)}}>Rare</span>. Each Rarity has an associated weight value (See "Rarity Weights" Table below). </p>
                        <p>When selecting an item from a <strong>Loot Table</strong> the total weight of all the <strong>Loot</strong> is calculated. A random number from 1 to the total weight is picked. This number is used to select an item at random from the Loot Table.</p>
                        <p>Take in mind that the total number of <strong>Loot</strong> items in a <strong>Loot Table</strong> affects the chances of an item being picked. For example, if there are only 3 Loot items in a Loot Table of Rarity <span style={{color:getRarityColor(Rarity.Common)}}>Common</span>, <span style={{color:getRarityColor(Rarity.Common)}}>Common</span>, and <span style={{color:getRarityColor(Rarity.Uncommon)}}>Uncommon</span>. Then there is a 4/5th (80%) chance of selecting one of the <span style={{color:getRarityColor(Rarity.Common)}}>Common</span> items and a 1/5th (20%) chance of selecting an <span style={{color:getRarityColor(Rarity.Uncommon)}}>Uncommon</span> item.</p>
                        <p>However, if there are 20 <strong>Loot</strong> items in a <strong>Loot Table</strong> all being <span style={{color:getRarityColor(Rarity.Common)}}>Common</span> except 1 being <span style={{color:getRarityColor(Rarity.Uncommon)}}>Uncommon</span> then there is a 38/39th (97.5%) chance of selecting one of the <span style={{color:getRarityColor(Rarity.Common)}}>Common</span> items and a 1/39th (2.5%) chance of selecting an <span style={{color:getRarityColor(Rarity.Uncommon)}}>Uncommon</span> item.</p>

                        <h4>Rarity Weights</h4>
                        <ul>
                            {this.getRarityListItems()}
                        </ul>

                        <h4>Selection Examples</h4>

                        <h5>Example 1</h5>
                        <p>A Loot Table with <strong>1</strong> Loot item of each Rarity type has the probability of:</p>
                        <ul>
                            <li>Total Weight: <strong>{ex1Total}</strong></li>
                            <li>Chance of picking a <span style={{color:getRarityColor(Rarity.Junk)}}>Junk</span> Loot item: <strong>{this.calculateProb(Rarity.Junk,1,ex1Total)}%</strong></li>
                            <li>Chance of picking a <span style={{color:getRarityColor(Rarity.Common)}}>Common</span> Loot item: <strong>{this.calculateProb(Rarity.Common,1,ex1Total)}%</strong></li>
                            <li>Chance of picking an <span style={{color:getRarityColor(Rarity.Uncommon)}}>Uncommon</span> Loot item: <strong>{this.calculateProb(Rarity.Uncommon,1,ex1Total)}%</strong></li>
                            <li>Chance of picking a <span style={{color:getRarityColor(Rarity.Rare)}}>Rare</span> Loot item: <strong>{this.calculateProb(Rarity.Rare,1,ex1Total)}%</strong></li>
                            <li>Chance of picking an <span style={{color:getRarityColor(Rarity.Epic)}}>Epic</span> Loot item: <strong>{this.calculateProb(Rarity.Epic,1,ex1Total)}%</strong></li>
                            <li>Chance of picking a <span style={{color:getRarityColor(Rarity.Legendary)}}>Legendary</span> Loot item: <strong>{this.calculateProb(Rarity.Legendary,1,ex1Total,3)}%</strong></li>
                        </ul>

                        <h5>Example 2</h5>
                        <p>A Loot Table with <strong>5</strong> <span style={{color:getRarityColor(Rarity.Common)}}>Common</span> Loot items, <strong>3</strong> <span style={{color:getRarityColor(Rarity.Uncommon)}}>Uncommon</span> Loot items, and <strong>1</strong> <span style={{color:getRarityColor(Rarity.Epic)}}>Epic</span> Loot item has the probability of:</p>
                        <ul>
                            <li>Total Weight: <strong>{ex2Total}</strong></li>
                            <li>Chance of picking a <span style={{color:getRarityColor(Rarity.Junk)}}>Junk</span> Loot item: <strong>{this.calculateProb(Rarity.Junk,0,ex2Total)}%</strong></li>
                            <li>Chance of picking a <span style={{color:getRarityColor(Rarity.Common)}}>Common</span> Loot item: <strong>{this.calculateProb(Rarity.Common,5,ex2Total)}%</strong></li>
                            <li>Chance of picking an <span style={{color:getRarityColor(Rarity.Uncommon)}}>Uncommon</span> Loot item: <strong>{this.calculateProb(Rarity.Uncommon,3,ex2Total)}%</strong></li>
                            <li>Chance of picking a <span style={{color:getRarityColor(Rarity.Rare)}}>Rare</span> Loot item: <strong>{this.calculateProb(Rarity.Rare,0,ex2Total)}%</strong></li>
                            <li>Chance of picking an <span style={{color:getRarityColor(Rarity.Epic)}}>Epic</span> Loot item: <strong>{this.calculateProb(Rarity.Epic,1,ex2Total)}%</strong></li>
                            <li>Chance of picking a <span style={{color:getRarityColor(Rarity.Legendary)}}>Legendary</span> Loot item: <strong>{this.calculateProb(Rarity.Legendary,0,ex2Total)}%</strong></li>
                        </ul>

                        <h5>Example 3</h5>
                        <p>A Loot Table with <strong>3</strong> <span style={{color:getRarityColor(Rarity.Rare)}}>Rare</span> Loot items, <strong>3</strong> <span style={{color:getRarityColor(Rarity.Epic)}}>Epic</span> Loot items, and <strong>5</strong> <span style={{color:getRarityColor(Rarity.Legendary)}}>Legendary</span> Loot items has the probability of:</p>
                        <ul>
                            <li>Total Weight: <strong>{ex3Total}</strong></li>
                            <li>Chance of picking a <span style={{color:getRarityColor(Rarity.Junk)}}>Junk</span> Loot item: <strong>{this.calculateProb(Rarity.Junk,0,ex3Total)}%</strong></li>
                            <li>Chance of picking a <span style={{color:getRarityColor(Rarity.Common)}}>Common</span> Loot item: <strong>{this.calculateProb(Rarity.Common,0,ex3Total)}%</strong></li>
                            <li>Chance of picking an <span style={{color:getRarityColor(Rarity.Uncommon)}}>Uncommon</span> Loot item: <strong>{this.calculateProb(Rarity.Uncommon,0,ex3Total)}%</strong></li>
                            <li>Chance of picking a <span style={{color:getRarityColor(Rarity.Rare)}}>Rare</span> Loot item: <strong>{this.calculateProb(Rarity.Rare,3,ex3Total)}%</strong></li>
                            <li>Chance of picking an <span style={{color:getRarityColor(Rarity.Epic)}}>Epic</span> Loot item: <strong>{this.calculateProb(Rarity.Epic,3,ex3Total)}%</strong></li>
                            <li>Chance of picking a <span style={{color:getRarityColor(Rarity.Legendary)}}>Legendary</span> Loot item: <strong>{this.calculateProb(Rarity.Legendary,5,ex3Total)}%</strong></li>
                        </ul>
                    </ContentBlock.Content>
                </ContentBlock>
            </div>
        );
    }

}

const blockStyle = {
    padding:"32px"
}