import { configureStore } from '@reduxjs/toolkit';
import {
    actionTypes as rrfActionTypes, firebaseReducer, getFirebase
} from 'react-redux-firebase';
import { constants as rfConstants } from 'redux-firestore';
import logger from 'redux-logger';
import userReducer, { userSlice } from './reducers/UserReducer';

const middleware = [logger];

// https://redux.js.org/usage/usage-with-typescript#standard-redux-toolkit-project-setup-with-typescript
// https://redux-toolkit.js.org/tutorials/typescript
// https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
export const store = configureStore({
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    // just ignore every redux-firebase and react-redux-firebase action type
                    ...Object.keys(rfConstants.actionTypes).map(
                        (type) => `${rfConstants.actionsPrefix}/${type}`
                    ),
                    ...Object.keys(rrfActionTypes).map(
                        (type) => `@@reactReduxFirebase/${type}`
                    ),
                    ...Object.keys(userSlice.actions).map(
                        (type) => `userState/${type}`
                    )
                ],
                ignoredPaths: ['firebase', 'firestore', 'userState']
            },
            thunk: {
                extraArgument: {
                    getFirebase
                }
            }
        }).concat(middleware);
    },
    reducer: {
        userState: userReducer,
        firebase: firebaseReducer
    }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch