import React from "react";
import { Pagination } from "react-bootstrap";

interface IProps extends React.HTMLAttributes<HTMLElement> {
    numPages: number;
    onClickPage: (event: React.MouseEvent<HTMLElement>, page: number) => void
}

interface IState {
    page: number;
}

export default class APagination extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            page: 1
        }
    }

    onItemClick(event: React.MouseEvent<HTMLElement>, page: number) {
        this.setState({page});
        document.querySelector('.app')?.scrollTo({top:0,behavior:'smooth'})
        this.props.onClickPage(event, page);
    }

    renderPages() {
        let pages = [];
        for(let i = 0; i<this.props.numPages; i++) {
            const pageNumber = i+1;
            pages.push(
                <Pagination.Item key={pageNumber} active={pageNumber === this.state.page} onClick={(e) => {this.onItemClick(e, pageNumber)}} >
                    {pageNumber}
                </Pagination.Item>
            )
        }
        return pages;
    }

    render(): React.ReactNode {
        if(this.props.numPages <= 1) return <></>;
        else return (
        <Pagination className="justify-content-center" style={{...this.props.style,...{margin:'0px'}}} >
            <Pagination.Prev onClick={(e) => {if(this.state.page > 1) this.onItemClick(e, this.state.page-1)}} />
            {this.renderPages()}
            <Pagination.Next onClick={(e) => {if(this.state.page < this.props.numPages) this.onItemClick(e, this.state.page+1) }} />
        </Pagination>);
    }

}

export const MAX_ITEM_PER_PAGE = 18;