import { createSlice } from '@reduxjs/toolkit';
import { User } from 'firebase/auth';
import { RootState } from '../Store';

type UserState = {
    user: User | null
}

const initialState: UserState = {
    user: null
}

export const userSlice = createSlice({
    name: 'userState',
    initialState,
    reducers: {
        setUser: (state, action) => {
            return  {
                ...state,
                user: action.payload
            };
        }
    }
});

export const { setUser } = userSlice.actions;
export const userState = (state: RootState) => state.userState;
export default userSlice.reducer;