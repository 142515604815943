import React, { CSSProperties, MouseEventHandler } from 'react';
import { Button, ButtonProps, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaLock } from 'react-icons/fa';
import { CONFIG } from '../../../utils/Constants';

export interface IProps extends ButtonProps {
    className?: string;
    style?: CSSProperties;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    tooltip?: React.ReactNode;
    editing?: boolean;
    variant?: string;
    editingVariant?: string;
    disabled?: boolean;
    size?: "sm" | "lg";
    lock?: boolean;
}

interface IState {

}

export default class CustomButton extends React.Component<IProps, IState> {

    renderButton() {
        return (
            <Button
                {...this.props}
                className={this.props.className + " a-button"}
                style={{ ...this.props.style, ...buttonStyle }}
                variant={this.props.editing ? this.props.editingVariant : this.props.variant}
                size={this.props.size ? this.props.size : "sm"}
            >
                <div style={style}>{this.props.children}</div>
                { !this.props.lock ? <></> : <FaLock style={{float:'right', marginTop:'6px', marginRight:'8px'}} className="icon" />}
            </Button>
        );
    }

    render() {
        return (
            <>
                { this.props.tooltip ? 
                    <OverlayTrigger placement="auto" delay={CONFIG.TOOLTIP.DELAY} overlay={<Tooltip>{this.props.tooltip}</Tooltip>}>
                        {this.renderButton()}
                    </OverlayTrigger> 
                    : this.renderButton()
                }
            </>
        );
    }

}

const buttonStyle: CSSProperties = {
    position: 'relative',
    minHeight: '32px',
    minWidth: '32px'
}

const style: CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: '100%'
}