import React, { CSSProperties } from 'react';
import { Dropdown } from 'react-bootstrap';
import { AlignType } from 'react-bootstrap/esm/types';
import { BsThreeDotsVertical } from 'react-icons/bs';

interface IProps {
    className?: string;
    style?: CSSProperties;
    align?: AlignType;
}

interface IState {

}

/**
 * 
 */
export default class ActionButton extends React.Component<IProps, IState> {

    public static Menu = Menu;

    render() {
        return (
            <Dropdown className={this.props.className} style={this.props.style} onClick={(e) => { e.stopPropagation() }} align={this.props.align} >
                <Dropdown.Toggle variant='info' style={toggleStyle} bsPrefix="px-2" >
                    <BsThreeDotsVertical className="icon" style={{color:'white'}} />
                </Dropdown.Toggle>
                <Dropdown.Menu variant="dark">
                    {this.props.children}
                </Dropdown.Menu>
            </Dropdown>
        );
    }

}

const toggleStyle: CSSProperties = {
    padding:'0px 8px',
    margin:'0'
}

//************************************************************************************************ 
//*  Menu
//************************************************************************************************/

interface IMenuProps {
    className?: string;
    style?: CSSProperties;
    onClick: (event: any) => void;
    text: string;
}

function Menu(props: IMenuProps){
    
    return (
        <Dropdown.Item className={props.className} style={props.style} onClick={props.onClick}>
            {props.text}
        </Dropdown.Item>
    );

}

//************************************************************************************************ 
//*  Render Options
//************************************************************************************************/

export interface ActionMenuRenderOptions {
    text: string;
    onClick: (event: any, data: any) => void;
}
