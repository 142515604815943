import { ISelectable, ISelector } from '../model/interfaces/ISelector';
import { getRandomInt } from '../utils/AppUtilities';
import { log } from '../utils/Logger';

export default class WeightSelector implements ISelector {

    CLASSNAME = this.constructor.name;

    select(selectable: ISelectable): any[] {
        const results: any[] = [];
        const totalWeight: number = selectable.getTotalWeight();
        const randomQuantity = getRandomInt(selectable.minQuantity, selectable.maxQuantity);
        const items = selectable.getItems();
        // for each item to be picked
        for (let i = 0; i < randomQuantity; i++) {
            const randomWeight = getRandomInt(1, totalWeight);
            let currentWeightCount = 0;
            // look in loot table and pick the correct loot
            for (let j = 0; j < selectable.getItems().length; j++) {
                let loot = items[j];
                currentWeightCount += loot.rarity;
                if (currentWeightCount >= randomWeight) {
                    results.push(loot);
                    break;
                }
            }
        }
        log(this.CLASSNAME+" - select", "Selected " + results.length + " item(s) from " + selectable.toString())
        return results;
    }

}