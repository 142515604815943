import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import React from 'react';
import { Alert, Button, FloatingLabel, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { prettyFirebaseLog } from '../../utils/AppUtilities';
import { COLOR, EVENT, ROUTE } from '../../utils/Constants';
import { logAnalyticsEvent, logError } from "../../utils/Logger";

interface IProps {

}

interface IState {
    email: string;
    password: string;
    errorMsg: string;
    success: boolean;
}

export default class SignUpForm extends React.Component<IProps, IState> {

    CLASSNAME = this.constructor.name;

    constructor(props: IProps) {
        super(props);
        this.state = {
            email: "",
            password: "",
            errorMsg: "",
            success: false
        }
    }

    createAccount(event: any) {
        event.preventDefault();
        const auth = getAuth();
        createUserWithEmailAndPassword(auth, this.state.email, this.state.password).then((userCredentials) => {
            this.setState({ success: true });
            logAnalyticsEvent(EVENT.NEW_USER_ACCOUNT);
            toast("Account created successfully");
        }).catch((e) => {
            this.setState({ errorMsg: e.message });
            logError(this.CLASSNAME + " - createAccount", e);
        });
    }

    onEmailChange(event: any) {
        this.setState({ email: event.target.value })
    }

    onPasswordChange(event: any) {
        this.setState({ password: event.target.value });
    }

    render() {
        return (
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel label="Email Address" style={{color:'black'}} >
                            <Form.Control style={style} type="email" placeholder="Enter Email" value={this.state.email} onChange={(e) => { this.onEmailChange(e) }} />
                        </FloatingLabel>
                        <Form.Text style={textStyle}>
                            We will never share your personal information with anyone else.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <FloatingLabel label="Password" style={{color:'black'}} >
                            <Form.Control style={style} type="password" placeholder="Password" value={this.state.password} onChange={(e) => { this.onPasswordChange(e) }} />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <FloatingLabel label="Confirm Password" style={{color:'black'}} >
                            <Form.Control style={style} type="password" placeholder="Password" />
                        </FloatingLabel>
                    </Form.Group>
                    {this.state.errorMsg.trim() !== "" ? <Alert variant="danger"> There was an issue: {prettyFirebaseLog(this.state.errorMsg)} </Alert> : null}
                    {this.state.success ? <Alert variant="success"> Profile successfully created! You will be automatically logged in. </Alert> : null}
                    <Button variant="primary" type="submit" onClick={(e) => { this.createAccount(e) }}>
                        Create Account
                    </Button>
                    <Link to={ROUTE.LOGIN}>
                        <Button style={{ float: "right" }} variant="secondary">
                            I already have an account
                        </Button>
                    </Link>
                </Form>
        );
    }

} 

const style = {
    backgroundColor: COLOR.PRIMARY_TAN,
    borderColor: COLOR.SECONDARY_TAN
}

const textStyle = {
    color: 'white'
}