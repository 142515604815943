import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getAuth, NextOrObserver, onAuthStateChanged, signOut, User } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { toast } from 'react-toastify';
import { isDevEnv, isTestEnv } from './AppUtilities';
import { log, logError } from './Logger';

//************************************************************************************************ 
//*  Firebase - initialization
//************************************************************************************************/

// https://firebase.google.com/docs/web/setup#available-libraries
const firebaseConfig = {
    apiKey: "AIzaSyALGolAtuX8fwI09JOApvC0H1PNQM1UcG8",
    authDomain: "alderman.firebaseapp.com",
    projectId: "alderman",
    storageBucket: "alderman.appspot.com",
    messagingSenderId: "825333527222",
    appId: "1:825333527222:web:05f06b1776ee7662d76c17",
    measurementId: "G-8CJX558EYZ"
};

export const app = initializeApp(firebaseConfig); // init firebase
export const analytics = getAnalytics(); // init analytics
export const firestore = getFirestore(); // init firestore
export const auth = getAuth();

// @ts-ignore enable developer mode
if (isDevEnv() || isTestEnv()) window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;

// initialize recaptcha app-check
initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider("6LdF_7gdAAAAAJlyAYHL5IX6qc2mWQcMfehQsK6r"),
    isTokenAutoRefreshEnabled: true
});

//************************************************************************************************ 
//*  Firebase - auth
//************************************************************************************************/

export function onAuthChange(listener: NextOrObserver<User>) {
    return onAuthStateChanged(auth, listener);
}

export function signOutUser() {
    signOut(auth).then(() => {
        toast("Logged Out Successfully")
        log("FirebaseUtils - signOutUser", "User Signed Out");
    }).catch((e) => {
        logError("FirebaseUtils - signOutUser", e);
    });
}

