import React, { CSSProperties } from 'react';
import { Container, Nav, Navbar, NavDropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaBars } from 'react-icons/fa';
import { Else, If, Then } from 'react-if';
import { connect, ConnectedProps } from "react-redux";
import { Link } from 'react-router-dom';
import { NEW_SESSION } from '../../controllers/datastore/Datastore';
import { setUser } from "../../controllers/redux/reducers/UserReducer";
import { RootState } from "../../controllers/redux/Store";
import { getGravatarHash } from "../../utils/AppUtilities";
import { ROUTE, SCREEN, URL } from '../../utils/Constants';
import { signOutUser } from "../../utils/FirebaseUtils";
import VideoPrompt from './prompts/VideoPrompt';

//************************************************************************************************ 
//*  State & Props
//************************************************************************************************/
interface IProps extends PropsFromRedux {}

interface IState {
    showTooltip: boolean;
    isMobile: boolean;
    showAboutVideo: boolean;
}

//************************************************************************************************ 
//*  Redux
//************************************************************************************************/
const mapState = (state: RootState) => ({
    user: state.userState.user
});

const mapDispatch = {
    setUser: setUser
}

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

//************************************************************************************************ 
//*  Component
//************************************************************************************************/
class Header extends React.Component<IProps, IState> {

    CLASSNAME = this.constructor.name;

    constructor(props: IProps) {
        super(props);
        const newSession = sessionStorage.getItem(NEW_SESSION);
        console.log(newSession)
        this.state = {
            showTooltip: false,
            isMobile: window.innerWidth < SCREEN.MEDIUM,
            showAboutVideo: newSession === null && this.props.user === null ? true : false
        }
    }

    componentDidMount() {
        // resize
        window.addEventListener('resize', () => {
            this.setState({
                isMobile: window.innerWidth < SCREEN.MEDIUM
            });
        }, false);
        
        if(!this.props.user) {
            this.setState({showTooltip: true});
            document.querySelector('.app')?.scrollTo({top:1}); // workaround for tooltip bug
            setTimeout(() => {
                this.setState({showTooltip: false});
            }, 5000);
        }

        sessionStorage.setItem(NEW_SESSION, 'true');
    }

    renderTooltip() {
        return (
            <Tooltip>
                Login to unlock more features
            </Tooltip>
        );
    }

    render() {
        const user = this.props.user;
        const email = this.props.user?.email;
        const emailHash = email ? getGravatarHash(email) : "";

        console.log(user)

        return (
            <div style={style}>
                <If condition={this.state.showAboutVideo && user === null} >
                    <VideoPrompt onClickClose={() => {this.setState({showAboutVideo:false})}} />
                </If>
                <Navbar bg="dark" variant="dark" expand="md" fixed="top">
                    <Container>
                        <Navbar.Brand as={Link} to={ROUTE.HOME}>
                            <img
                                src="/img/logo192.png"
                                width="30"
                                height="30"
                                alt="Alderman logo"
                            />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link as={Link} to={ROUTE.HOME}>Play</Nav.Link>
                                <NavDropdown title="Manage" menuVariant="dark">
                                    <NavDropdown.Item as={Link} to={ROUTE.LOOT}>Loot</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={ROUTE.LOOT_TABLE}>Loot Tables</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link href={URL.HOW_TO_LOOT} target="_blank">How It Works</Nav.Link>
                            </Nav>
                            <Nav>
                                <Nav.Link onClick={() => {this.setState({showAboutVideo:true})}}>What is Alderman RPG?</Nav.Link>
                                <If condition={user !== null}>
                                    <Then>
                                        <NavDropdown title={ this.state.isMobile ? "More" : <FaBars className="mb-1" /> } align='end' menuVariant="dark">
                                            <NavDropdown.Item as={Link} to={ROUTE.USER_PROFILE}>Profile</NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item onClick={signOutUser} >Log Out</NavDropdown.Item>
                                        </NavDropdown>
                                        <If condition={true}>
                                            <Navbar.Brand as={Link} to={ROUTE.USER_PROFILE} className="m-0">
                                                <img src={"https://www.gravatar.com/avatar/" + emailHash + "?s=64"} style={avatarStyle} alt="Profile Avatar" />
                                            </Navbar.Brand>
                                        </If>
                                    </Then>
                                    <Else>
                                        <OverlayTrigger placement="bottom" show={this.state.showTooltip} overlay={this.renderTooltip()}>
                                            <Nav.Link as={Link} to={ROUTE.LOGIN}>Login</Nav.Link>
                                        </OverlayTrigger>
                                    </Else>
                                </If>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        );
    }

}

const style = {
    boxShadow: "0px 35px 30px rgb(0, 0, 0,.25)"
}

const avatarStyle: CSSProperties = {
    width: '30px',
    height: '30px',
    borderRadius: '50%'
}

export default connector(Header);