import React from 'react';
import { Spinner } from 'react-bootstrap';
import PromptOverlay from './prompts/PromptOverlay';

interface IProps {
    
}

interface IState {
    
}

export default class LoadingSpinner extends React.Component<IProps, IState> {

    render() {
        return (
            <PromptOverlay hideClose>
                <div className="center">
                    <Spinner animation="border" variant="danger" />
                </div>
            </PromptOverlay>
        );
    }

}